.add-event-form {
  width: 100%;
  max-width: 800px;
  &--type {
    max-width: 400px;
  }
  &--title {
    max-width: 400px;
  }
  &--crafts-workshop {
    max-width: 400px;
  }
  &--user-count {
    max-width: 400px;
  }
  &--date {
    max-width: 400px;
  }
  &__wrapper {
    // padding: 0 20px;
    @media (max-width: 756px) {
      padding: 0;
    }
  }
  &__box {
    border: 1px solid var(--border-line);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    background-color: var(--primary-color-light);
    box-shadow: var(--box-shadow);
    transition: 0.3s;
  }
  .btn-wrapper {
    margin-left: auto;
    justify-content: flex-end;
    .btn {
      padding: 8px 30px;
    }
  }
  &__hide-element {
    max-height: 0;
    opacity: 0;
    transition: 0.8s;
    &--active {
      max-height: 1000px;
      opacity: 1;
      transition: 0.8s;
    }
  }
  .btn-wrapper {
    margin-top: 20px;
  }
}
