.shop-page {
  display: flex;
  flex-direction: column;
  // .filter {
  //   cursor: pointer;
  //   padding: 8px 16px;
  //   border-radius: 4px;
  //   font-weight: 500;
  //   border: 1px solid var(--primary-color);
  //   color: var(--primary-color);
  //   &__wrapper {
  //     display: flex;
  //     align-items: center;
  //   }
  //   .svg {
  //     margin-right: 10px;
  //     path {
  //       stroke: var(--primary-color);
  //     }
  //   }
  // }
  .top {
    flex-direction: row !important;
    border-bottom: 1px solid transparent;
    transition: 0.3s;
    &--filter-open {
      box-shadow: none !important;
      transition: 0.3s;
      border-bottom: 1px solid var(--border-line);
    }
  }
  .products {
    padding: 20px 10px;
  }
}
