.regular-field {
  &__container {
    position: relative;

    &--full-width input {
      width: 100%;
    }
  }
  &__input {
    display: flex;
    align-items: center;
  }
  &__wrapper {
    &--half {
      display: flex;
      column-gap: 20px;
      .custom-field {
        width: 50%;
      }
    }
  }
  &__input input {
    border: 1px solid rgba(0, 29, 61, 0.3);
    line-height: 20px;
    padding: 10px 15px 10px 46px;
    border-radius: 8px;
    margin: 12px 0;
    cursor: pointer;
    font-family: Inter;
    outline-style: none;
    font-weight: 600;
  }
  &:hover &__input input {
    border: 1px solid rgba(0, 29, 61, 0.55);
  }
  &:hover &__icon path {
    fill: rgba(0, 29, 61, 0.55);
    // stroke: rgba(0, 29, 61, 0.55);
  }

  &:focus &__input input,
  &:visited &__input input {
    border: unset;
  }
  &:focus-within &__input input {
    border: 1px solid #003566;
  }
  &:focus-within &__icon path {
    fill: rgba(0, 29, 61, 1);
    // stroke: rgba(0, 29, 61, 1);
  }
  &--noIcon input {
    padding: 10px 20px;
  }
  &__icon {
    position: absolute;
    display: flex;
    align-items: center;
    path {
      fill: rgba(0, 29, 61, 0.3);
    }
    &--item {
      width: 15px;
    }
    &--left {
      left: 15px;
    }
    &--right {
      right: 15px;
    }
  }
  &__error-label {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 14px;
    color: #d00000;
    font-weight: 600;
    background: linear-gradient(transparent 48%, #fff 50%);
  }
  &__input-error {
    border: 1px solid #d00000 !important;
  }
}

.custom-field {
  &__error-label {
    position: absolute;
    right: 0;
    bottom: 0px;
    font-size: 12px;
    color: #d00000;
    font-weight: 600;
    // background: linear-gradient(transparent 48%, #fff 50%);
  }
  &__input-error {
    border-bottom: 2px solid #d00000 !important;
  }

  $primary: var(--primary-color);
  $secondary: var(--secondary-color);
  $gray: #322525;
  .custom-field__input {
    position: relative;
    padding: 14px 0 14px;
    margin-top: 5px;
  }

  .form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $gray;
    outline: 0;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-color);
    padding: 8px 0;
    background: transparent;
    transition: border-color 0.2s;
    position: relative;
    z-index: 4;
    border-radius: 0;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form__label {
      font-size: 16px;
      cursor: text;
      top: 24px;
    }
  }

  .form__label {
    position: absolute;
    top: 3px;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: $gray;
    &--error {
      color: #d00000;
    }
  }

  .form__field:focus {
    ~ .form__label {
      position: absolute;
      top: 5px;
      display: block;
      transition: 0.2s;
      font-size: 13px;
      color: $primary;
      font-weight: 700;
    }
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;
  }

  .form__field {
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }

  .custom-textarea-field {
    .form__field {
      height: 80px;
    }
    .custom-field .form__field:placeholder-shown ~ .form__label {
      top: 80px;
    }
  }
}

.customize-field {
  font-family: "Montserrat";
  textarea {
    min-height: 200px;
  }
  &__container {
    position: relative;
  }
  &__error-label {
    position: absolute;
    right: 0;
    font-size: 13px;
    color: var(--price-color);
    font-weight: 500;
  }
  &__input {
    display: flex;
    flex-direction: column-reverse;
    &-error {
      border: 1px solid var(--price-color) !important;
    }
    .form {
      &__field {
        line-height: 20px;
        padding: 10px 15px 10px 14px;
        border-radius: 4px;
        margin: 16px 0;
        cursor: pointer;
        font-family: Inter;
        outline-style: none;
        font-weight: 500;
        border: 1px solid rgba(0, 0, 0, 0.15);
        color: rgba(0, 0, 0, 0.6);
        background-color: rgba(187, 187, 187, 0.02);
        font-size: 14px;
      }
      &__label {
        font-size: 16px;
      }
    }
  }
}

.customize-field__input {
  position: relative;
}

.form__field[type="number"]::-webkit-outer-spin-button,
.form__field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form__field[type="number"] {
  -moz-appearance: textfield;
}
