.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &--item {
    margin: 12px 12px;
    svg {
      height: 30px;
      width: 30px;
      cursor: pointer;
    }
    // path {
    //   fill: $color__gray;
    // }
  }
}
