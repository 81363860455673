.forum-question {
  &__top {
    display: flex;
    min-height: 50px;
  }
  &__top-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
  }
  &__top-right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__user-name {
      font-size: 14px;
      font-weight: 600;
      margin-top: 6px;
      color: #161d2e;
    }
    &__bottom {
      display: flex;
      font-size: 14px;
      margin-bottom: 6px;
    }
    &__bottom-crafts {
      font-weight: 500;
      color: rgba(22, 29, 46, 0.5);
    }
    &__bottom-date {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-weight: 500;
        margin: 0 5px;
        color: rgba(22, 29, 46, 0.5);
      }
      .svg {
        margin-right: 10px;
        path {
          fill: rgba(22, 29, 46, 0.5);
        }
      }
      span {
        font-weight: 500;
        color: rgba(22, 29, 46, 0.5);
      }
    }
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    color: #161d2e;
    margin-bottom: 20px;
    &--icon {
      margin-left: auto;
      cursor: pointer;
    }
  }
}

.forum-question-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  &__top {
    display: flex;
  }
  &__top-icon {
    width: 50px;
    font-size: 48px;
    font-weight: 800;
    color: rgba(22, 29, 46, 0.85);
  }
  &__top-title {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: rgba(22, 29, 46, 0.9);
  }
  &__center {
    display: flex;
  }
  &__center-left {
    width: 50px;
    flex-shrink: 0;
  }
  &__center-right {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 16px;
    line-height: 20px;
  }
  &__bottom {
    margin-top: 10px;
    margin-left: 60px;
    display: flex;
    border-bottom: 1px solid rgba(22, 29, 46, 0.2);
    padding-bottom: 20px;
    &__tags {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      color: rgba(22, 29, 46, 0.5);
      .svg {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        path {
          fill: rgba(22, 29, 46, 0.5);
        }
      }
    }
  }
  &__buttons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    padding-left: 60px;
  }
}

.forum-question-answer {
  margin-top: 40px;
  padding-left: 60px;
  &__single {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(22, 29, 46, 0.1);
  }
  &__heading {
    font-size: 18px;
    font-weight: 600;
    color: rgba(22, 29, 46, 1);
    margin: 20px 0;
  }
  &__text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
  }
}
