@import "./_reset";
@import "./_variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

#root,
body,
html {
  height: 100%;
}

body {
  min-height: 100vh;
  background-color: var(--body-color);
  transition: var(--tran-05);
  font-family: "Montserrat";
}
.stop-scrolling {
  overflow: hidden;
}
.full-height {
  height: 100%;
}
// .paragraph {
//   font-size: 16px;
// }
.bold {
  font-weight: 600;
}
.center {
  display: flex;
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
// body .dark {
//   --body-color: #18191a;
//   --sidebar-color: #242526;
//   --primary-color: #3a3b3c;
//   --primary-color-light: #3a3b3c;
//   --toggle-color: #fff;
//   --text-color: #ccc;
// }

.main-wrapper {
  height: 100%;
  &--blurred {
    background-color: rgba($color: #000000, $alpha: 0.6);
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
  }
}

.counter--active:after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 3px;
  top: -6px;
  width: 6px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
  transition: var(--tran-03);
}
.counter--active:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: var(--sidebar-color);
  box-sizing: var(--box-shadow);
  border: 1px solid var(--navbar--color);
  top: -7px;
  right: -7px;
  position: absolute;
  transition: var(--tran-03);
}

.heading {
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
  @media (max-width: 600px) {
    font-size: 22px;
  }
}
