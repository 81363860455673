.profile-user-type-view {
  padding: 20px;
  box-shadow: var(--box-shadow);
  max-width: 100%;
  border-radius: 8px;
  transition: 0.3s;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  background-color: var(--primary-color-light);
  &__heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__text {
    font-size: 16px;
    font-weight: 500;
    max-width: 1000px;
    line-height: 140%;
    &--mid {
      margin-bottom: 20px;
    }
    &--bottom {
      margin-bottom: 10px;
    }
  }
}

.profile-user-type-carts {
  display: flex;
  gap: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
.profile-user-type-cart {
  box-shadow: var(--box-shadow);
  border: 1px solid rgba(0, 0, 0, 0.03);
  height: auto;
  border-radius: 8px;
  padding: 20px;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-light);
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &__img-wrapper {
    max-width: 100%;
    flex-shrink: 0;
  }
  &__img {
    border-radius: 8px;
    width: 100%;
    object-fit: cover;
  }
  &__heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__text {
    font-size: 16px;
    line-height: 140%;
    max-width: 700px;
    font-weight: 500;
  }
  &--descript {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    height: 100%;
  }
  &__button {
    margin-top: auto;
    width: 100%;
    .btn {
      margin-top: 20px;
      width: 100%;
    }
  }
}
