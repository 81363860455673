@import "./components/ProfilePageTopBar.scss";
@import "./components/ProfileUserTypeBar.scss";
@import "./components/ProfileUserTypeView.scss";
@import "./components/UserTypeModal.scss";

// .shop-page,
// .favourites-page,
// .users,
// .add-product-page,
// .category-page {
//   display: flex;
//   .top {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
//     box-shadow: var(--box-shadow);
//     border-right: var(--border-line);
//   }
// }

.profile-page {
  .content-inner {
    height: 100vh;
    // width: calc(100vh - 20px);
    width: 100%;
    background-color: var(--third-color);
    // padding: 0 40px 20px 40px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      &--full {
        padding: 0;
      }
    }
  }
  .content {
    width: 100%;
    // max-width: 1400px;
    display: flex;
    &--right {
      width: 100%;
      height: 100%;
      display: flex;
    }
    &__wrapper {
      height: calc(100vh);
      display: flex;
      justify-content: center;
    }
  }
}

// .profile {
//   display: flex;
//   justify-content: space-between;
//   height: 100px;
//   align-items: center;
//   .avatar {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     border-radius: 80px;
//     &__wrapper {
//       align-items: center;
//       display: flex;
//       height: 80px;
//       padding-left: 15px;
//     }
//     &__content {
//       width: 50px;
//       height: 50px;
//       border-radius: 80px;
//     }
//   }
//   .name {
//     text-align: left;
//     font-weight: 400;
//     margin-left: 15px;
//   }
//   .category {
//     color: #9d9d9d;
//     font-size: 12px;
//     font-weight: 500;
//     margin-left: 14px;
//     margin-top: 5px;
//   }
// }

// .profile-menu {
//   &__content {
//     border-top: 1px solid var(--border-line);
//   }
//   &__content {
//     display: flex;
//   }
//   &__item {
//     padding: 15px 10px;
//     font-size: 14px;
//     cursor: pointer;
//   }
// }
