.forum-last-added {
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    flex-direction: column;
  }
}

.forum-last-added-item {
  padding: 15px 0;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid rgba(22, 28, 45, 0.05);

  &__top {
    display: flex;
    .svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  &__name {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
  }
  &__bottom {
    display: flex;
    margin-top: 5px;
  }
  &__date {
    font-size: 14px;
    color: rgba(22, 28, 45, 0.5);
    font-weight: 600;
    margin-left: 28px;
    margin-right: 15px;
    display: flex;
    align-items: center;
  }
}

.forum-last-added-item-user {
  display: flex;
  align-items: center;
  &__image-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  &__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &__name {
    font-size: 14px;
    font-weight: 500;
    color: rgba(22, 28, 45, 1);
  }
}
