.input-counter {
  display: flex;
  border-radius: 8px;
  input {
    height: 32px;
    max-width: 32px;
    // border-radius: 8px;
    border: 1px solid #003566;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    background-color: #003566;
    color: #fff;
    font-weight: 800;
    pointer-events: none;
    @media (max-width: 756px) {
      margin: 0;
      border-radius: 0;
    }
  }
  .button {
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #003566;
    background-color: #003566;
    color: #fff;
    font-weight: 500;
    transition: 0.3s;
    &:hover {
      background-color: #001930;
      transition: 0.3s;
    }
    &--disabled {
      pointer-events: none;
    }
  }
  .decrease {
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .increase {
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
