@import "./components/forum-sidebar-left.scss";
@import "./components/forum-sidebar-right.scss";
@import "./components/forum-tags.scss";
@import "./components/forum-last-added.scss";
@import "./components/forum-center.scss";
@import "./components/forum-top-users.scss";
@import "./components/forum-top-articles.scss";
@import "./components/forum-add-question.scss";
@import "./components/forum-sidebar-advertisement.scss";
@import "./components/forum-question.scss";
@import "./components/forum-sidebar-products";

.forum-page {
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 40px;
  }
  &__header {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    color: #161d2e;
    margin-bottom: 20px;
    &--icon {
      margin-left: auto;
      cursor: pointer;
    }
  }
}
.forum-page__header-icon {
  display: flex;
  align-items: center;
  .svg--add-tags {
    path {
      fill: #161d2e;
    }
  }
}
