.my-account {
  .settings-dots {
    .view {
      overflow: hidden;
      &--active {
        overflow: unset;
      }
    }
    .list {
      margin-right: -20px;
      &--item {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        position: relative;
        max-height: 42px;
        min-width: 200px;
        &__icon {
          transition: 0.3s;
          &--active {
            transition: 0.3s;
            transform: scaleY(-1);
          }
        }
        &__wrapper {
          max-height: 42px;
          position: relative;
        }
        &__onclick {
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 60px);
          height: 100%;
        }
        .svg {
          max-width: 14px;
          max-height: 14px;
        }
        .svg--chevrone {
          width: 20px;
          height: 20px;
        }
        .svg--logout path {
          fill: var(--price-color);
        }
      }
    }
  }
  .sub-list {
    position: relative;
    left: -200px;
    top: -30px;
    background-color: var(--menu--left);
    box-shadow: var(--box-shadow--active);
    border-radius: 8px;
    opacity: 0;
    max-height: 0px;
    transition: 0.3s;
    overflow: hidden;
    &--active {
      opacity: 1;
      max-height: 400px;
      transition: 0.3s;
    }
    &__item {
      border: 0;
    }
  }
}
