.events-filter {
  display: flex;
  max-height: 0;
  overflow: hidden;
  transition: 0.1s;
  gap: 20px;
  // margin-bottom: 20px;
  padding: 0 20px;
  margin-left: -20px;
  transition: 0.3s;
  &--active {
    padding: 20px;
    transition: 0.3s;
    max-height: 100px;
    transition: 0.1s;
  }

  .filter {
    background-color: var(--primary-color-light);
    padding: 10px 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    &:hover {
      box-shadow: var(--box-shadow--active);
    }
    &__text {
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .filter-color {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 3px;
  }
}
