@import "./components/EventsTable.scss";
@import "./components/EventsFilter.scss";
.event-manage-content {
  transition: 0.3s;
  &--hidden {
    transition: 0.3s;
    max-height: 0px;
  }
  &--expand {
    transition: 0.3s;
    max-height: 1000px;
  }
}
.event-menu {
  margin-bottom: 20px;
  .advertisement-menu__list {
    flex-wrap: wrap;
  }
  .advertisement-menu__item {
    border: 3px solid #c4c4cc;
  }
}
