.forum-tags {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__content-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    svg {
      path {
        fill: #161d2e;
      }
    }
  }
  &__more {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    .btn-wrapper,
    .btn {
      width: 100%;
    }
  }
}

.forum-tag {
  color: #161d2e;
  padding: 8px 16px;
  background-color: var(--primary-color-light);
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  border: 1px solid transparent;
  display: flex;
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &--active {
    border: 1px solid var(--primary-color-light);
    background-color: #161d2e;
    color: var(--primary-color-light);
  }
  &--noclick {
    cursor: auto;
  }
}

.forum-tag-actions {
  margin-left: 10px;
  transition: 0.3s;
  span {
    cursor: pointer;
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
}
