.select-field {
  width: 100%;
  margin: 16px 0px;
  font-family: "Montserrat";
  &__wrapper {
    position: relative;
    width: 100%;
  }
  &__label {
    top: 11px;
    left: 6px;
    font-size: 16px;
    &--is-disabled {
      color: #bdbdbd !important;
    }
    // @media (max-width: 756px) {
    //   background: transparent;
    //   &:after {
    //     content: '';
    //     width: 100%;
    //     height: 1px;
    //     background-color: white;
    //     display: block;
    //     margin-top: -6px;
    //   }
    // }
    padding: 0 2px;
    z-index: 1;
  }
  &__input {
    &--error {
      .react-select__control {
        border: 1px solid #e20d0d !important;
      }
    }
  }
  &__error-label {
    font-size: 13px;
    font-weight: $weight__regular;
    color: var(--price-color);
    text-align: right;
    font-weight: 500;
    &-only2 {
      margin-top: 15px;
      margin-left: 2px;
    }
  }
  .react-select {
    &__control {
      background-color: #fff;
      background-color: rgba(187, 187, 187, 0.02);
      border: 1px solid rgba(0, 0, 0, 0.15);

      &--is-focused {
        border: unset;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
        // 1px solid
        // border: 1px solid black;
      }
      &--menu-is-open {
        .svg--chevron {
          transform: rotate(180deg);
        }
      }
    }
    &__indicator-separator {
      display: none;
    }

    &__indicators {
      margin-right: 5px;
    }
    &__menu {
      box-shadow: 2px 4px 11px #00000014;
      z-index: 99;
    }
    &__value-container {
      padding: 9px 15px 9px 14px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.3);
      font-weight: 700;
    }
    &__placeholder {
      // color: #272727;
      color: rgba(0, 0, 0, 0.3);
      font-size: 14px;
      font-weight: 500;
    }
    &__menu,
    &__single-value,
    &__input {
      font-size: 12px;
      font-family: "Montserrat";
    }
    &__single-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #272727;
      &--is-disabled {
        color: #bdbdbd;
      }
    }
    &--is-disabled {
      path {
        fill: #bdbdbd;
      }
    }
    .svg--chevrone {
      width: 12px;
      height: 12px;
    }
  }
}
.css-lpahdxg-control {
  border-color: black !important;
}
.select--disabled {
  pointer-events: none;
  .react-select__control {
    cursor: not-allowed;
  }
  .select-field__label,
  .react-select__placeholder,
  .svg {
    cursor: not-allowed;
    path {
      fill: #c4c4c4;
    }
  }
}
.react-select__option--is-focused {
  background-color: #eeeeee !important;
}

.react-select__multi-value {
  padding: 5px 10px !important;
  border-radius: 8px;
  color: #161d2e !important;
  background-color: var(--primary-color-light) !important;
  border: 1px solid #161d2e !important;
  border-radius: 8px !important;
}
// .react-select__multi-value__label {
//   color: var(--primary-color-light) !important;
// }

.css-xb97g8:hover {
  transition: 0.3s;
  background-color: transparent !important;
  color: #161d2e !important;
  scale: 1.2 !important;
  cursor: pointer;
}
