.products {
  height: 100%;
  &__content {
    display: flex;
    flex-wrap: wrap;
    // row-gap: 30px;
    // column-gap: 30px;
    justify-content: center;
    gap: 20px;
    .slick-slider {
      width: 100%;
    }
    @media (max-width: 756px) {
      row-gap: 40px;
    }
  }
}
