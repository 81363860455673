.sip {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &__item-wrapper {
    &:hover {
      .sip__item-actions {
        visibility: visible;
      }
      .sip__item-edit {
        visibility: visible;
      }
    }
  }
  &__item {
    display: flex;
    gap: 20px;
    border: 1px solid #cecece;
    border-radius: 24px;
    padding: 15px;
    max-width: 500px;
    position: relative;
    height: 180px;
    &__date {
      height: 42px;
      display: flex;
      gap: 15px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #868686;
      position: absolute;
      top: 15px;
      right: -230px;
      width: 160px;
      &:after {
        content: "";
        width: 2px;
        height: 200px;
        background-color: #868686;
        position: absolute;
        top: 0;
        left: -20px;
      }
      &--future {
        color: #000 !important;
      }
      input {
        margin-top: -10px;
      }
    }
  }
  &__item-edit {
    visibility: hidden;
    cursor: pointer;
  }
  &__item-actions {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -40px;
    top: 60px;
    gap: 15px;
  }
  &__item-action {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    &:hover {
      transition: 0.3s;
      box-shadow: var(--box-shadow);
    }
    svg {
      width: 16px;
      height: 16px;
    }
    &--top {
      transform: rotate(180deg);
    }
  }
  &__status {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -28px;
    position: absolute;
    &:after {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      border: 20px;
      background-color: #ecebeb;
      border-radius: 20px;
      z-index: 9;
      border: 4px solid #dedede;
    }
    .svg {
      z-index: 999;
    }
    &--publicated {
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: #18bd3c;
        }
      }
    }
    &--warning {
      svg {
        width: 16px !important;
        height: 16px !important;
        path {
          fill: #d6314a;
        }
      }
    }
  }
  &__item-today {
    position: absolute;
    top: 40px;
    right: -130px;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 8px;
    background-color: #f6f5ff;
    border-radius: 14px;
  }
  &__item-image {
    width: 150px;
    height: 150px;
    border-radius: 14px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 14px;
    }
  }
  &__item-heading {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    -webkit-line-clamp: 6j;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.sip__list {
  .sip__item-wrapper:last-child {
    .sip__item__date {
      &:after {
        height: 20px;
      }
    }
  }
}
