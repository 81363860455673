.forum-top-articles {
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(22, 28, 45, 0.05);
    padding-bottom: 10px;
    &:nth-child(n + 2) {
      margin-top: 5px;
    }
  }
  &__item-top {
    display: flex;
  }
  &__item-avatar {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  &__item-avatar-text {
    font-size: 14px;
    font-weight: 600;
    color: rgba(22, 29, 46, 1);
    margin-left: 5px;
  }
  &__item-avatar-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  &__item-bottom {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    margin-top: 10px;
    align-items: center;
  }
  &__item-number {
    font-size: 14px;
    font-weight: 600;
    background-color: #161d2e;
    color: var(--primary-color-light);
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }
  &__item-title {
    font-weight: 500;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
  }
  &__item-view {
    display: flex;
  }
  &__item-comment {
    display: flex;
    margin-right: 10px;
  }
  &__item-comment-icon {
    margin-right: 5px;
    path {
      fill: rgba(22, 29, 46, 0.5);
    }
  }
  &__item-view-icon {
    margin-right: 5px;
    width: 16px;
    height: 16px;
    path {
      stroke: rgba(22, 29, 46, 0.5);
    }
  }
  &__item-view-number {
    font-size: 14px;
    font-weight: 500;
    color: rgba(22, 29, 46, 0.5);
  }
}
