.forum-add-question {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  background-color: var(--primary-color-light);
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: var(--box-shadow--active);
  }
  .checkbox {
    margin-bottom: 30px;
  }
}
