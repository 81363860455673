.table-list {
  display: flex;
  border: 1px solid black;
  flex-direction: column;
  border-radius: 4px;
}
.table-list-item {
  border: 1px solid black;
  padding: 10px 15px;
  font-size: 16px;
  &--image {
    padding: 0;
    max-width: 50px;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__header {
    font-weight: 600;
  }
}
.all-accounts-instagram {
  margin-top: 50px;
  .table-list-item {
    max-width: calc(100% / 6);
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}

.instagram-profiles {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.instagram-profile {
  display: flex;
  padding: 20px 30px;
  border: 1px solid #cecece;
  border-radius: 40px;
  position: relative;
  &__last-update {
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-size: 14px;
    color: #9c9a9a;
  }
  &__refresh {
    position: absolute;
    top: 20px;
    right: 40px;
    cursor: pointer;
  }
  &:hover,
  &.active {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &__image-wrapper {
    width: 150px;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  &__name {
    font-size: 18px;
    font-weight: 600;
  }
  &__bio {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    &:hover {
      .instagram-profile__bio-edit {
        visibility: visible;
      }
    }
  }
  &__bio-edit {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: #cecece;
    margin-top: -25px;
    margin-left: -5px;
    margin-right: -10px;
    border-radius: 10px;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    &--top {
      margin-bottom: 20px;
      display: flex;
      gap: 20px;
    }
  }
  &__counter-label {
    font-weight: 500;
    display: flex;
    align-items: center;
    &.active {
      cursor: pointer;
    }
    svg {
      visibility: hidden;
      margin-left: 5px;
      width: 12px;
      height: 12px;
    }
    &:hover {
      svg {
        visibility: visible;
      }
    }
  }
  &__counter-value {
    margin-top: 5px;
    text-align: center;
  }
}

.instagram-adding {
  margin-left: auto;
}

.instagram-bio-edit {
  min-width: 500px;
}

.instagramPostIdeas {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border: 1px solid #cecece;
  border-radius: 40px;
  position: relative;
  box-shadow: var(--box-shadow--active);
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &__badges {
    display: flex;
    gap: 20px;
  }
  &__badge {
    font-size: 18px;
    font-weight: 600;
    padding: 8px 18px;
    border: 1px solid #cecece;
    border-radius: 20px;
    cursor: pointer;
    &.active {
      background-color: #fff;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 40px;
    cursor: pointer;
    svg {
      width: 22px;
      height: 22px;
    }
    svg path {
      fill: #cecece;
    }
  }

  &__ideas {
    margin-top: 20px;
    border-radius: 10px;
  }
  &__idea {
    display: flex;
    border: 1px solid #cecece;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    &.click {
      cursor: pointer;
    }
    &.edit {
      background-color: #fff;
    }
    &.open {
      background-color: #fff;
    }
    &:hover {
      .hover {
        visibility: visible;
      }
    }
    &--name {
      display: flex;
    }
    form,
    input {
      width: 100%;
      margin: 0;
      padding: 0;
      background: unset;
      border: transparent !important;
      font-size: 18px;
      font-weight: 300;
      &:hover {
        border: transparent !important;
      }
    }
    form {
      margin-right: 60px;
    }
  }
  &__idea-edit {
    position: absolute;
    right: 20px;
    top: 20px;
    visibility: hidden;
    cursor: pointer;
    &.visible {
      visibility: visible;
    }
    .svg {
      width: 18px;
      height: 18px;
    }
    &.accept {
      right: 80px;
    }
    &.close,
    &.remove {
      right: 50px;
      svg path {
        fill: #000;
      }
    }
  }
  &__idea-number {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-top: 3px;
    &:after {
      content: ". ";
      margin-right: 10px;
    }
  }
  &__idea-label {
    font-size: 18px;
    line-height: 24px;
    margin-right: 80px;
  }
}

.instagramPostIdeasAccepted {
  &__heading {
    display: flex;
    .item {
      font-size: 18px;
      font-weight: 600;
      width: 100%;
      border: 1px solid #cecece;
      padding: 4px 10px;
      min-width: 160px;
    }
    .lp {
      width: 45px;
      min-width: 45px;
      display: flex;
      align-content: space-between;
    }
    .tags {
      min-width: 500px;
    }
  }
  &__list {
    display: flex;
    .remove {
      margin-top: auto;
      margin-bottom: 10px;
    }
    .item {
      width: 100%;
      padding: 8px 10px;
      border: 1px solid #cecece;
      font-size: 16px;
      line-height: 20px;
      min-width: 160px;
    }
    .lp {
      width: 45px;
      flex-shrink: 0;
      text-align: center;
      font-weight: 600;
      min-width: 0;
      display: flex;
      align-content: space-between;
      flex-direction: column;
      justify-content: space-between;
      div {
        cursor: pointer;
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      min-width: 500px;
      align-content: flex-start;
      span {
        padding: 4px 8px;
        font-size: 14px;
      }
    }
    .idea-caption {
      position: relative;
      &__form {
        .regular-field__container {
          position: unset;
        }
        .visible {
          z-index: 999;
          bottom: 8px;
          right: 40px;
          top: unset;
        }
        .visible2 {
          right: 8px;
          svg path {
            fill: #000;
          }
        }
        .form__field {
          border: unset;
          margin: 0;
          height: 100%;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          z-index: 99;
          padding: 8px;
          resize: none;
          left: 0;
        }
      }
      &:hover .hover {
        visibility: visible;
      }
      &__item {
        visibility: hidden;
        cursor: pointer;
        padding: 2px;
      }
      &__actions {
        gap: 10px;
        position: absolute;
        display: flex;
        bottom: 0;
        right: 0;
        padding: 4px 8px;
      }
      &__edit svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.instagram-tags {
  display: flex;
  gap: 4px 8px;
  flex-wrap: wrap;
}
.instagram-tag {
  font-size: 16px;
  padding: 8px 16px;
  border: 1px solid #cecece;
  border-radius: 10px;
  display: flex;
  &__delete {
    visibility: hidden;
    margin-left: 5px;
  }
  &:hover {
    .hidden {
      visibility: visible;
    }
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    svg path {
      fill: #000;
    }
  }
}
.instagram-empty-tag {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__top {
    margin: 0 auto;
  }
  &__bottom {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
  }
}

.idea-image {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
