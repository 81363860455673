@import "./components/counters.scss";

.users {
  display: flex;
  flex-direction: column;
  .content {
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    &__wrapper {
      margin: 30px;
      height: calc(100vh - 124px);
      display: flex;
      justify-content: center;
    }
  }
}

// .counters {
//   display: flex;
//   gap: 20px;
//   padding: 0 20px;
//   flex-wrap: wrap;
//   margin-top: 40px;
//   @media (max-width: 756px) {
//     flex-direction: column;
//   }
//   .counter {
//     border-radius: 8px;
//     cursor: pointer;
//     position: relative;
//     flex-grow: 1;
//     max-width: 100%;
//     transition: var(--tran-04);
//     box-shadow: var(--box-shadow);
//     &:hover {
//       box-shadow: var(--box-shadow--active);
//     }
//     @media (max-width: 756px) {
//       display: flex;
//     }
//     &--active {
//       box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//       scale: 1.1;
//       transition: var(--tran-04);
//       @media (max-width: 756px) {
//         scale: 1;
//       }
//     }
//     &__label {
//       font-weight: 600;
//       padding: 12px 30px;
//       font-weight: 500;
//       border: 1px solid var(--navbar--color);
//       border-top-left-radius: 8px;
//       border-top-right-radius: 8px;
//       text-align: center;
//       @media (max-width: 756px) {
//         border-radius: unset;
//         border: none;
//         display: flex;
//         align-items: center;
//         width: 190px;
//       }
//       &--client {
//         background-color: var(--client--color);
//       }
//       &--craftsman {
//         background-color: var(--craftsman--color);
//       }
//       &--distributor {
//         background-color: var(--distributor--color);
//       }
//       &--editor {
//         background-color: var(--editor--color);
//       }
//     }
//     &__amount {
//       border-bottom-left-radius: 8px;
//       border-bottom-right-radius: 8px;
//       border: 1px solid var(--navbar--color);
//       border-top: 0;
//       text-align: center;
//       padding: 12px 0;
//       font-size: 30px;
//       font-weight: 900;
//       background-color: #fff;
//       @media (max-width: 756px) {
//         border: unset;
//         margin-left: 10px;
//       }
//     }
//   }
// }

.user-table {
  width: 100%;
  border-radius: 8px;
  .rt-tr-group {
    background-color: #fff;
    box-shadow: var(--box-shadow);
    transition: var(--tran-03);
    margin: 10px 0;
    border-radius: 8px;
    &:hover {
      box-shadow: var(--box-shadow--active);
      transition: var(--tran-03);
    }
  }
  &__content {
    .rt-tr-group {
      padding: 15px 10px;
    }
    .rt-tr {
      display: flex;
    }
    .rt-td {
      display: flex;
      align-items: center;
      font-size: 14px;
      &:first-child {
        display: inline;
      }
    }
    .settings-dots {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
    }
  }
  .client {
    .nickname {
      width: 170px;
      padding-left: 45px;
    }
    .update-at {
      width: 100px;
    }
    .buy-elements {
      width: 100px;
    }
    .last-login {
      width: 100px;
    }
  }

  .table-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    &--wrapper {
      display: flex;
      background-color: #fff;
      padding: 17px 14px;
      font-weight: 500;
      border-radius: 8px;
    }
  }
}

.avatar {
  display: flex;
  align-items: center;
  font-size: 14px;
  &--paragraph {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-weight: 500;
    padding-left: 14px;
    padding-right: 20px;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  &__image-container {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    flex-shrink: 0;
  }
  &--dots {
    &--craftsman {
      border: 2.5px solid var(--craftsman--color);
    }
    &--distributor {
      border: 2.5px solid var(--distributor--color);
    }
    &--editor {
      border: 2.5px solid var(--editor--color);
    }
    &--client {
      border: 2.5px solid var(--client--color);
    }
  }
}
