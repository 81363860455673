.forum-center {
  width: 100%;
  max-width: 650px;
  height: 100%;
  border-radius: 10px;
  flex-shrink: 0;
  &--question {
    max-width: 800px;
  }
  &--normal {
    display: flex;
    flex-direction: column;
  }
}
.forum-center-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.forum-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forum-list-item {
  display: flex;
  padding: 20px;
  border: 1px solid rgba(22, 28, 45, 0.1);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &__user {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
    &__avatar {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__center {
    margin-right: 10px;
  }
  &__center-text {
    font-weight: 500;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
  }
  &__center-bottom {
    display: flex;
    margin-top: 8px;
  }
  &__craft {
    font-size: 12px;
    font-weight: 500;
    color: rgba(22, 29, 46, 0.5);
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__time {
    font-size: 12px;
    font-weight: 500;
    color: rgba(22, 29, 46, 0.5);
    margin-right: 20px;
    display: flex;
    align-items: center;
    .svg {
      margin-right: 5px;
      path {
        stroke: rgba(22, 29, 46, 0.5);
      }
    }
  }
  &__right {
    display: flex;
  }
  &__views,
  &__comments {
    display: flex;
    margin-right: 5px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(22, 29, 46, 0.5);
    display: flex;
    svg {
      margin-right: 10px;
    }
  }
}
