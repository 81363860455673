.user-icon {
  border-radius: 25px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  &--img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
  }
}