.home-page {
  .heading {
    font-weight: 800;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.04em;
    color: var(--first-color);
    &--center {
      text-align: center;
    }
    &--crafts {
      max-width: 500px;
      @media (max-width: 600px) {
        max-width: 100%;
      }
    }
  }
  &__crafts {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__forum {
    display: flex;
    align-items: center;
    flex-direction: column;
    .btn {
      margin-top: 40px;
    }
    .paragraph {
      max-width: 600px;
      text-align: center;
      margin-top: 40px;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.04em;
    }
  }
  &__shop {
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      max-width: 1016px;
      box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.2);
      border-radius: 24px;
      width: 100%;
      margin: 0 20px 60px 20px;
      padding: 45px;
      display: flex;
      flex-direction: column;
      margin-top: -2px;
      @media (max-width: 900px) {
        padding: 20px;
      }
      @media (max-width: 600px) {
        padding: 20px;
        margin-right: 0;
        margin-left: 0;
        box-shadow: unset;
      }
      .heading {
        font-weight: 700;
        font-size: 26px;
        line-height: 39px;
        letter-spacing: 0.04em;
        color: var(--second-color);
        margin-bottom: 25px;
      }
    }
    .box {
      display: flex;
    }
    .left {
      max-width: 580px;
      max-height: 540px;
      width: 100%;
      margin-right: 10px;
      overflow: hidden;
      flex: 50% 1;
      @media (max-width: 800px) {
        flex: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    // .right {
    //   flex: 50%;
    // }
    .list {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      max-width: 340px;
      &__navi {
        display: flex;
        margin-bottom: 20px;
        padding-left: 10px;
      }
      &__navi-item {
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        border: 1px solid var(--second-color);
        transition: 0.3s;
        cursor: pointer;
        svg path {
          transition: 0.3s;
        }
        &:hover {
          // background-color: var(--second-color);
          transition: 0.3s;
          svg path {
            transition: 0.3s;
            fill: var(--third-color);
          }
        }
        &:nth-child(1) {
          border-right: none;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          width: calc(50% - 5px);
        }
        &:nth-child(2) {
          border-right: none;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-right: 1px solid var(--second-color);
        }
      }
    }
    &__product {
      max-height: 160px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 10px;
      width: 100%;
      flex: 50%;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
      &--active {
        position: relative;
        cursor: auto;
        img {
          box-shadow: rgba(255, 255, 255, 0.3) 0px 1px 2px 0px,
            rgba(255, 255, 255, 0.15) 0px 2px 6px 2px;
        }
      }
      &:nth-child(even) {
        padding-right: 0;
      }
      &:nth-child(1),
      &:nth-child(2) {
        padding-top: 0;
      }
      &:nth-child(5),
      &:nth-child(6) {
        padding-bottom: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    padding: 0 20px;
    margin-bottom: 40px;
    @media (max-width: 400px) {
      margin-top: 30px;
    }
    .first-line {
      font-weight: 300;
      font-size: 30px;
      line-height: 46px;
      font-family: "Spectral SC";
      text-transform: uppercase;
      color: var(--second-color);
      @media (max-width: 400px) {
        font-size: 24px;
      }
    }
    .second-line {
      font-family: "Spectral SC";
      font-weight: 700;
      font-size: 55px;
      line-height: 84px;
      letter-spacing: 0.1em;
      color: var(--second-color);
      @media (max-width: 600px) {
        font-size: 45px;
      }
      @media (max-width: 400px) {
        font-size: 30px;
      }
    }
    .descript {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--second-color);
      max-width: 740px;
      text-align: center;
    }
  }
  &__ourPartners {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
    @media (max-width: 800px) {
      margin-top: 20px;
    }
    .slideArrow {
      &--left {
        @media (max-width: 1300px) {
          left: 20px !important;
          z-index: 99;
        }
      }
      &--right {
        @media (max-width: 1300px) {
          right: 20px !important;
        }
      }
    }
    .slick-dots li button {
      &:before {
        font-size: 14px;
        color: var(--third-color);
      }
    }
    .slick-dots .slick-active button:before {
      color: var(--second-color);
    }
    .heading {
      color: var(--second-color);
      max-width: 580px;
      text-align: center;
    }
    .content {
      max-width: 100%;
    }
    .slick-slider {
      &:nth-child(2),
      &:first-child {
        max-width: 1100px;
        margin: 0 auto;
        margin-top: 46px;
      }
    }
    .slick-center {
      background-color: var(--second-color);
      border-radius: 10px;
      transition: 0.3s;
      box-shadow: rgba(255, 255, 255, 0.3);
    }
    .slider {
      &__partner {
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &__descript {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 39px;
        text-align: center;
        letter-spacing: 0.04em;
        color: var(--second-color);
        max-width: 1100px;
        padding: 0 20px;
      }
    }
  }
  .slideArrow {
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 0;
    transform: translate(0, -50%);
    background: #161d2e;
    border-radius: 30px;
    box-shadow: -1px -1px 4px rgba(255, 255, 255, 0.25),
      1px 1px 4px rgba(255, 255, 255, 0.25);
    &--left {
      left: -55px;
    }
    &--right {
      .svg {
        transform: rotate(180deg);
      }
      right: -55px;
    }
  }
  &__opinions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .slick-list {
      height: 300px;
      @media (max-width: 800px) {
        margin-top: -20px;
        margin-bottom: 20px;
      }
    }
    .slick-track {
      margin-top: 40px;
    }
    .slick-slider {
      position: relative;
    }
    .slick-dots {
      bottom: 10px;
    }
    .slick-dots li button {
      &:before {
        font-size: 14px;
      }
    }
    .slick-slider .slideArrow {
      @media (max-width: 900px) {
        display: none !important;
      }
    }

    .heading {
      max-width: 300px;
      text-align: center;
      margin-bottom: 96px;
    }
    .content {
      max-width: 925px;
      display: flex;
      .slick-slider {
        max-width: 100%;
      }
    }
    .single {
      width: 252px;
      height: 255px;
      border: 1px solid var(--first-color);
      border-radius: 12px;
      &__wrapper {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
      &--active {
        // margin: -36px 30px 0 30px;
        margin-top: -36px;
      }
    }
    .paragraph {
      font-weight: 800;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.04em;
      color: var(--first-color);
    }
    .top {
      padding: 20px 17px;
      svg {
        margin-bottom: 10px;
      }
    }
    .name {
      font-weight: 800;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.04em;
    }
    .craft {
      font-weight: 800;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.04em;
      color: rgba(22, 29, 46, 0.5);
    }
    .labels {
      margin-left: 17px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
    .bottom {
      border-top: 1px solid var(--first-color);
      display: flex;
      padding: 17px;
    }
  }
}
