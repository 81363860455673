.modal__inner--user-type-modal {
  max-width: 700px;
  width: 100%;
}

.user-type-modal {
  display: flex;
  gap: 40px;
  &__category {
    margin: 10px 0;
  }
  &__heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }
  &__buttons {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    .btn-wrapper,
    .btn {
      min-width: 150px;
      flex-shrink: 0;
    }
  }
  &--left {
    flex-shrink: 0;
    .checkbox {
      max-width: 300px;
    }
  }
  &--right {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
