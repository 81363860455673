.filters {
  height: 0px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  padding: 0 20px;
  &--open {
    height: 150px;
    transition: 0.3s;
    overflow: auto;
    padding: 40px 20px;
  }
}
