@import "./components/checkbox.scss";
@import "./components/date-picker-field.scss";

.form {
  &--paragraph {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    cursor: pointer;
  }
}
