.checkbox {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;
  &__error {
    position: absolute;
    bottom: -16px;
    left: 0;
    color: #ff0000;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.022em;
  }
  &__label {
    display: block;
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    letter-spacing: 0.022em;
    margin-top: 10px;
    cursor: pointer;
    @media (max-width: 756px) {
      padding-right: 8px;
    }
    &--active {
      &:after {
        background-color: #161d2e;
        border: 1px solid #161d2e;
      }
      &:before {
        content: "";
        top: 1px;
        left: 11px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        border-radius: 1px;
        z-index: 9;
      }
    }
    &:after {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 3px;
      position: absolute;
      border: 1px solid #dddddd;
      transition: 0.3s;
      left: 5px;
      top: -2px;
    }
  }
}
