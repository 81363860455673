.submenu {
  display: flex;
  position: relative;
  overflow: scroll;
  max-width: 1400px;
  width: 100%;
  padding: 20px;
  margin-left: -20px;
  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 0 30px;
    border: 2px solid #161d2e;
    border-radius: 30px;
    box-shadow: var(--box-shadow);
    &--active {
      transition: 0.6s;
      background-color: rgba(199, 199, 199, 0.4);
      box-shadow: var(--box-shadow--active);
      background-color: #dedede;
      .submenu__item--name {
        font-weight: 600;
        color: #161d2e;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &--name {
      font-weight: 500;
      font-size: 14px;
      color: #161d2e;
    }
    &--count {
      font-size: 14px;
      margin-left: 4px;
      font-weight: 600;
      letter-spacing: 0.7px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      color: #161d2e;
      transition: 0.6s;
    }
  }
}
