.basket-modal {
  background-color: #fff;
  display: flex;
  z-index: 99999999999;
  min-height: 100vh;
  position: absolute;
  top: 0;
  max-width: 1024px;
  right: -400px;
  width: 100%;
  transition: 0.3s;
  @media (max-width: 1111px) {
    max-width: 100%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
  &--active {
    right: 0;
    transition: 0.3s;
  }
  &--h1 {
    font-size: 24px;
    font-weight: 700;
    color: #001e3d;
    display: flex;
    align-items: center;
  }
  .back {
    cursor: pointer;
    margin-bottom: 30px;
    font-size: 12px;
    color: var(--main-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 30px 30px 10px 30px;
    .svg {
      transform: rotate(90deg);
      width: 10px;
      height: 10px;
      margin-right: 10px;
      path {
        fill: var(--main-color);
      }
    }
  }
  .left {
    width: calc(100% - 340px);
    padding-top: 140px;
    @media (max-width: 1000px) {
      width: calc(100% - 280px);
    }
    @media (max-width: 900px) {
      width: 100%;
      position: relative;
    }
    .basket-modal--h1 {
      padding-bottom: 30px;
      padding: 30px 30px 40px 30px;
    }
    .top {
      width: calc(100% - 340px);
      @media (max-width: 1000px) {
        width: calc(100% - 280px);
      }
      @media (max-width: 900px) {
        width: 100%;
      }
      background-color: #fff;
      box-shadow: var(--box-shadow);
      &--sticky {
        position: fixed;
        background-color: #fff;
        z-index: 1;
        top: 0;
      }
    }
  }
  .right {
    width: 340px;
    background-color: var(--body-color);
    box-shadow: var(--box-shadow);
    z-index: 2;
    position: relative;
    @media (max-width: 1000px) {
      width: 280px;
    }
    @media (max-width: 900px) {
      width: 100%;
      position: unset !important;
    }
    &--close {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      @media (max-width: 900px) {
        position: fixed !important;
      }
      .svg {
        width: 13px;
        height: 13px;
      }
    }
    &__summary {
      height: calc(100% - 134px);
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .btn {
        width: 100%;
        padding: 20px 15px;
        border-radius: 4px;
        margin-top: 10px;
      }
      &--item {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        font-size: 14px;
        font-weight: 500;

        span {
          font-weight: 600;
        }
      }
    }
    &--sticky {
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
    }
    .basket-modal--h1 {
      padding: 80px 30px 30px 30px;
    }
  }
}

.basket {
  &--items {
    display: flex;
    flex-direction: column;
    margin: 0 25px;
    padding: 20px 0 30px 0;
    position: relative;
    // &:after {
    //   content: "";
    //   width: 9999px;
    //   position: absolute;
    //   height: 100%;
    //   top: 100%;
    //   background-color: #fff;
    //   margin-left: -25px;
    // }
  }
  &--item {
    border: 1px solid var(--border-line);
    border-radius: 8px;
    display: flex;
    margin: 16px 0;
    padding: 11px;
    position: relative;
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    @media (max-width: 650px) {
      flex-direction: column;
      padding: 22px;
    }
    &:hover {
      transition: 0.3s;
      box-shadow: var(--box-shadow--active);
    }
    &--close {
      position: absolute;
      top: -8px;
      right: -5px;
      width: 20px;
      height: 20px;
      background-color: var(--primary-color-light);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      box-shadow: var(--box-shadow--active);
      cursor: pointer;
      transition: 0.3s;
      @media (max-width: 650px) {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        top: -14px;
        right: -10px;
      }
      &:hover {
        transition: 0.3s;
      }
      .svg {
        width: 8px;
        height: 8px;
      }
    }
    .image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
      &--wrapper {
        cursor: pointer;
        border-radius: 4px;
        width: 140px;
        height: 100px;
        overflow: hidden;
        flex-shrink: 0;
        @media (max-width: 650px) {
          width: 100%;
          max-height: 250px;
          height: 100%;
          margin: 0 auto;
          order: 1;
        }
      }
    }
    .mid {
      line-height: 130%;
      font-size: 14px;
      font-weight: 500;
      max-width: 260px;
      width: 100%;
      padding: 5px 20px 5px 20px;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      @media (max-width: 650px) {
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-top: 10px;
        order: 3;
      }
      &--name {
        margin-bottom: 10px;
      }
      &--counter {
        display: flex;
        align-items: center;
        p {
          margin-left: 5px;
          font-size: 12px !important;
          font-size: var(--text-color);
        }
      }
      .input-counter .button,
      .input-counter input {
        height: 24px;
        width: 24px;
        @media (max-width: 650px) {
          height: 35px;
          width: 35px;
        }
      }
    }
    .right--price {
      text-align: right;
      font-size: 22px;
      font-weight: 500;
    }
    &__right {
      margin: 5px 5px 5px 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      @media (max-width: 650px) {
        margin: 0;
        align-items: flex-start;
        margin: 20px 0 10px 0;
        order: 2;
      }
      .watched {
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        @media (max-width: 650px) {
          margin-top: 6px;
        }
        &:hover {
          font-weight: 600;
        }
        .svg {
          margin-left: 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
