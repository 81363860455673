// FONT FAMILY

// FONT WEIGHT
$weight__light: 300;
$weight__regular: 400;
$weight__medium: 500;
$weight__bold: 700;
$weight__black: 900;

// COLORS
$color__black--20: rgba(0, 8, 20, 0.2);
$color__darkBlue: #001e3d;
$color__gray: #dde2e6;
$color__red: #d6314a;
$color__gray--hover: hsla(210, 7%, 71%, 0.6);
$color_MidGray: #afb4b9;
$color__lightDarkBlue: #134679;
$color__black: rgba(0, 8, 20, 1);
// FONT COLORS

:root {
  --first-color: #161d2e;
  --second-color: #b69c7f;
  --third-color: #dedede;
  --main-color: #001e3d;
  --box-shadow: 0 5px 10px rgba(21, 12, 12, 0.05);
  --box-shadow--white: 0 5px 10px rgba(255, 255, 255, 0.05);
  --box-shadow--white--active: 0 5px 10px rgba(255, 255, 255, 0.1);
  --box-shadow--active: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --sidebar-box-shadow: hsla(0, 0%, 39%, 0.2) 0px 2px 8px 0px;
  --primary-color: #023e8a;
  --primary-color-light: #f6f5ff;
  --secondary-color: #0077b6;
  --toggle-color: #ddd;
  --text-color: #707070;
  --navbar--color: #262626;
  --client--color: #8ac926;
  --distributor--color: #1982c4;
  --craftsman--color: #6a4c93;
  --editor--color: #ffca3a;
  --menu--left: #fbfbfb;
  --price-color: #d00000;
  --border-line: hsla(0, 0%, 15%, 0.1);

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}
