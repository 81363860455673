.product {
  min-width: 250px;
  max-width: 320px;
  box-shadow: var(--sidebar-box-shadow);
  border-radius: 10px;
  padding: 22px 20px 15px 20px;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  background-color: var(--primary-color-light);
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &--loading {
    cursor: pointer;
    background-color: var(--primary-color-light);
    .mid {
      width: 100%;
    }
    .nick,
    .price {
      width: calc(50% - 10px);
    }
  }
  .mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    color: var(--price-color);
    font-weight: 500;
  }
  .image {
    max-width: 480px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    border-radius: 8px;
    position: relative;
    @media (min-width: 600px) {
      &:hover {
        scale: 1.1;
        transition: 0.5s;
        border-radius: 8px;
        cursor: pointer;
      }
    }
    &:after {
      content: "";
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 9;
    }
    &--wrapper {
      width: 100%;
      height: 220px;
      overflow: hidden;
      border-radius: 8px;
      @media (max-width: 460px) {
        height: 280px;
      }
    }
  }
  .heading {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    cursor: pointer;
    text-transform: uppercase;
    padding: 0 !important;
  }
  .actions {
    margin-top: auto;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    flex-wrap: wrap;
    .btn {
      font-weight: 600;
      margin-top: 12px;
      flex-grow: 1;
      display: flex;
      font-size: 12px;
    }
  }
}
.nick {
  margin: 10px 0;
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  text-transform: lowercase;
  color: #9d9d9d;
  cursor: pointer;
}
