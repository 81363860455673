.profile-page-top-bar {
  width: 100%;
  display: flex;
  z-index: 1;
  position: sticky;
  top: 0;
  background-color: var(--primary-color-light);
  border-top-left-radius: 40px;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 15px 20px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    bottom: -1px;
    left: 0;
  }
  &__left {
    display: flex;
    align-items: center;
    &--menu {
      height: 30px;
      width: 30px;
      @media (max-width: 768px) {
        height: 25px;
        width: 25px;
      }
    }
    &--name {
      font-weight: 600;
      font-size: 20px;
      margin-left: 20px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
  &__right {
    margin-left: auto;
  }
  &__profile {
    .my-account-badge__nickname {
      color: var(--first-color);
    }
    .svg--chevrone path {
      fill: var(--first-color);
    }
  }
}
