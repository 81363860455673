.photo-field {
  border: 1px dashed rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  height: 350px;
  display: block;
  border-radius: 8px;
  background-color: rgba(228, 233, 247, 0.3);
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &--error {
      border: 1px dashed var(--price-color);
    }
  }
  &__content {
    max-width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__paragraph {
    text-align: center;
    margin: 15px 0;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.3);
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .file-types {
    display: none;
  }

  &__photos {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    &--content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__photo {
    max-width: calc(100% / 4 - 22px);
    margin: 10px;
    width: 100%;
    max-height: calc(50% - 40px);
    height: 100%;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--tran-03);
    cursor: grab;
    &--hidden {
      display: none;
    }
    .svg--pluss-circle {
      height: 60px;
      width: 60px;
      path {
        transition: var(--tran-03);
        fill: rgba(2, 61, 138, 0.3);
      }
    }
    &:hover {
      transition: var(--tran-03);
      box-shadow: var(--box-shadow--active);
      .photo-field__photo--close {
        opacity: 1;
        transition: var(--tran-03);
      }
      .svg--pluss-circle path {
        transition: var(--tran-03);
        fill: rgba(2, 61, 138, 0.7);
      }
    }
    &--image {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
    &--first {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 4px;
      text-align: center;
      width: 100%;
      background-color: rgba(2, 61, 138, 0.9);
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &--add {
      cursor: pointer;
      &--disabled {
        display: none;
      }
    }
    &--close {
      width: 25px;
      height: 25px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      position: absolute;
      box-shadow: var(--box-shadow--active);
      bottom: -10px;
      right: -10px;
      cursor: pointer;
      z-index: 9;
      opacity: 0;
      transition: var(--tran-03);
      .svg {
        max-width: 8px;
        max-height: 8px;
      }
    }
  }
}

.sortable-chosen {
  cursor: grabbing;
}
// .zIGoD {
//   cursor: pointer !important;
// }
.sc-gswNZR,
.iBDCDI {
  display: none !important;
}
