.legend {
  &__items {
    background-color: var(--primary-color-light);
    border-radius: 10px;
    height: 100%;
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    padding: 10px 10px 0 0;
    min-width: 200px;
    &:hover {
      transition: 0.3s;
      box-shadow: var(--box-shadow--active);
    }
    .events-page-section {
      box-shadow: unset;
      align-items: center;
      &:hover {
        box-shadow: unset;
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.legend-symbol {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;

  &--small {
    width: 10px;
    height: 10px;
    margin: 0 10px;
  }

  &--today {
    background-color: #54df8e;
  }
  &--workshop {
    background-color: #ee3c7b;
  }
}
.legend-text {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 20px;
  line-height: 20px;
}
