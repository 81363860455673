.counters {
  display: flex;
  gap: 10px;
  margin-left: 10px;
  .counter {
    min-height: 34px;
    padding: 4px 6px 4px 10px;
    border-radius: 12px;
    border: 3px solid;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.3s;
    overflow: hidden;
    &:hover {
      padding-right: 12px;
      .counter-label {
        max-width: 300px;
        opacity: 1;
        transition: 0.3s;
        transition-delay: 0.5s;
        z-index: 1;
      }
    }
    &--client {
      border-color: var(--client--color);
    }
    &--craftsman {
      border-color: var(--craftsman--color);
    }
    &--distributor {
      border-color: var(--distributor--color);
    }
    &--editor {
      border-color: var(--editor--color);
    }
  }
  .counter-label-number {
    align-items: center;
    justify-content: center;
    font-weight: 600;
    display: flex;
    height: 100%;
    color: #161d2e;
    margin-right: 4px;
  }
  .counter-label {
    font-size: 14px;
    font-weight: 600;
    color: #161d2e;
    max-width: 0px;
    opacity: 0;
    transition: max-width 0.3s, opacity 0.3s;
    transition-delay: 0s;
  }
}
