.circle-icon {
  align-items: center;
  background-color: $color__gray;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 10px;
  transition: 0.3s;
  width: 40px;
  &:hover {
    background-color: $color__gray--hover;
  }  
  &--disabled {
    pointer-events: none;
  }
  &--focused {
    background-color: $color__gray--hover;
  }
  &--image {
    width: 10px;
    height: 10px;
  }

}