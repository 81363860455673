.crafts-events {
  width: 500px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow--active);
  background-color: rgba(255, 255, 255, 0.9);
  padding-bottom: 20px;
  &__title {
    font-size: 18px;
    padding: 16px 20px;
    font-weight: 600;
    color: var(--main-color);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      cursor: pointer;
    }
    .svg {
      margin-left: auto;
      width: 25px;
      height: 25px;
      path {
        fill: var(--main-color);
      }
    }
  }

  .calendar-cart {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__month {
      font-size: 12px;
      color: rgba(0, 30, 61, 0.7);
      font-weight: 700;
      padding: 5px 19px 0;
    }
    &__date {
      width: 90px;
      height: 60px;
      position: relative;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      .svg {
        width: 60px;
        height: 60px;
        position: absolute;
        path {
          fill: var(--main-color);
        }
      }
    }
    &__date-number {
      margin-top: 18px;
      font-size: 24px;
      font-weight: 20px;
      font-weight: 600;
      color: var(--main-color);
    }
    &__descript {
      display: flex;
      flex-direction: column;
      padding: 6px 20px 6px 0;
    }
    &__descript-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--main-color);
      margin-bottom: 5px;
    }
    &__descript-second-line {
      font-size: 12px;
      color: rgba(0, 30, 61, 0.7);
      font-weight: 700;
    }
  }
}
