.profile-user-type-bar {
  width: 100%;
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.01);
  padding: 14px 24px;
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: space-between;
  &:hover {
    box-shadow: var(--box-shadow--active);
  }
  &__paragraph {
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;
    max-width: 700px;
  }
  .btn-wrapper {
    flex-shrink: 0;
    max-height: 40px;
  }
}
