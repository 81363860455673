.footer {
  width: 100%;
  padding-bottom: 50px;
  display: flex;
  @media (max-width: 600px) {
    margin-top: 30px;
  }
  &__content {
    max-width: 1200px;
    width: 100%;
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    @media (max-width: 600px) {
      justify-content: center;
      margin-left: 0;
    }
    &--item {
      text-decoration: none;
      font-weight: 800;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.04em;
      color: var(--second-color);
      &:nth-child(n + 1) {
        margin-bottom: 12px;
      }
      @media (max-width: 600px) {
        font-size: 18px;
        text-align: center;
        line-height: 28px;
        color: var(--second-color);
      }
    }
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }
  .socials {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    &__item {
      cursor: pointer;

      &:first-child {
        margin-right: 20px;
        @media (max-width: 600px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
