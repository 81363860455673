.star-rating {
  margin-top: 10px;
  &--content {
    display: flex;
    .svg {
      &:nth-child(n + 2) {
        margin-left: 1px;
      }
    }
  }
  &--rate {
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
  }
}
