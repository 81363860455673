.forum-top-users {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
  }
}

.forum-top-user {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 5px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(22, 28, 45, 0.05);
    &:last-child {
      border-bottom: 0;
    }
  }
  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e9e9e9;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &__name {
    font-size: 14px;
    font-weight: 600;
    color: #161d2e;
  }
  &__comment {
    display: flex;
  }
  &__comment-icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }
  &__comment-text {
    font-size: 12px;
    font-weight: 600;
    color: #161d2e;
  }
}
