.pagination {
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  border-radius: 8px;
  background-color: var(--primary-color-light);
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &-bottom {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 15px;
  }
  display: flex;
  .button {
    .svg {
      width: 10px;
      height: 10px;
      path {
        fill: var(--main-color);
      }
    }
    &:hover svg path {
      fill: #fff;
    }
    &--previous svg {
      transform: rotate(90deg);
    }
    &--next svg {
      transform: rotate(270deg);
    }
    &--disabled {
      pointer-events: none;
      .svg path {
        fill: #001e3d2a;
      }
    }
  }
  .box {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    transition: 0.3s;
    color: var(--main-color);
    font-weight: 500;
    &:hover {
      color: #fff;
      background-color: #161d2e;
      transition: 0.3s;
    }
    &:first-child {
      border-right: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-child {
      border-left: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &--active {
      color: #fff;
      background-color: #161d2e;
      transition: 0.3s;
    }
  }
}
