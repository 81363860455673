.dashboard-sidebar {
  max-width: 300px;
  width: 100%;
  height: 100%;
  background-color: #161d2e;
  transition: 0.3s;
  box-shadow: var(--box-shadow);
  flex-shrink: 0;
  @media (max-width: 768px) {
    max-width: 200px;
  }
  &--fit {
    transition: 0.3s;
    max-width: 80px;
    @media (max-width: 768px) {
      max-width: 50px;
    }
  }
  // &__user {
  //   display: flex;
  //   align-items: center;
  //   padding-left: 10px;
  //   margin-left: 10px;
  //   width: 100%;
  //   overflow: hidden;
  // }
  &__curve-element {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: -40px;
    background-color: #161d2e;
    overflow: hidden;
    &:after {
      content: "";
      width: 80px;
      height: 80px;
      background-color: var(--third-color);
      position: absolute;
      border-radius: 50px;
      top: 0px;
    }
  }
  &__hide-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    border-radius: 20px;
    right: 1px;
    width: 21px;
    height: 21px;
    background-color: var(--sidebar-color);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    .svg {
      height: 9px;
      width: 9px;
      transform: rotate(270deg);
      margin-left: 2px;
    }
    &--left {
      transform: rotate(90deg) !important;
      margin-left: -2px !important;
    }
    @media (max-width: 756px) {
      width: 20px;
      height: 20px;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    max-height: 90px;
    height: 100%;
    position: relative;
    &--user {
      color: #fff;
      font-weight: 600;
      margin-left: 25px;
      min-width: 100px;
      .svg {
        width: 30px;
      }
    }
  }
  &__top-avatar {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    path {
      fill: #fff;
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &--item-icon {
      width: 40px;
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 25px;
      @media (max-width: 768px) {
        margin-right: 5px;
      }
    }
    &--item-content {
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    &--item {
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding: 20px 0 20px 10px;
      font-weight: 600;
      color: #fff;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      cursor: pointer;
      @media (max-width: 768px) {
        padding-left: 0px;
      }
      .svg {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        transition: 0.3s;
      }
      .svg--forum path {
        fill: #fff;
      }
      .svg--gallery path {
        fill: #fff;
      }
      .svg--shop path {
        fill: #fff;
      }
      .svg--settings path {
        stroke: #fff;
      }
      .svg--user path {
        fill: #fff;
      }
      .svg--users path,
      .svg--calendar path,
      .svg--mail path {
        fill: #fff;
      }
      &--active {
        color: var(--main-color);
        background-color: var(--third-color);
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          height: 20px;
          width: 20px;
          bottom: 0;
          background: radial-gradient(
            circle at top left,
            #fff0 72%,
            #dedede 75%
          );
        }
        &:after {
          right: 0;
          top: 100%;
          background: radial-gradient(
            circle at bottom left,
            #fff0 70%,
            #dedede 75%
          );
        }

        &:before {
          bottom: 100%;
          right: 0;
        }
        p {
          font-weight: 700;
        }
        .svg--settings path {
          transition: 0.3s;
          stroke: var(--main-color);
        }
        .svg--gallery path,
        .svg--forum path,
        .svg--shop path,
        .svg--users path,
        .svg--user path,
        .svg--calendar path,
        .svg--mail path {
          transition: 0.3s;
          fill: var(--main-color);
        }
      }
      &--rounded {
        border-radius: 30px;
        margin-right: 10px;
        height: 60px;
        &:before,
        &:after {
          background: unset;
        }
      }
    }
    &--item-advertisement {
      background-color: var(--primary-color-light) !important;
      &::before {
        background: radial-gradient(
          circle at top left,
          #fff0 72%,
          var(--primary-color-light) 75%
        );
      }
      &:after {
        background: radial-gradient(
          circle at bottom left,
          #fff0 70%,
          var(--primary-color-light) 75%
        );
      }
    }
  }
}
