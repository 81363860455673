.modal {
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.26);
  &--email {
    font-weight: 700;
    text-align: center;
    margin: 20px 0 0 0;
  }
  &--h3 {
    font-weight: 600;
    text-align: center;
  }
  .close {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    .svg {
      width: 15px;
      height: 15px;
    }
  }
  &__inner {
    overflow: auto;
    padding: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 100%;
    height: 100%;
    &--add-instagram-account {
      .user-type-modal__buttons .btn-wrapper {
        width: 50%;
        .btn {
          width: 100%;
        }
      }
    }
    &--instagram-bio-edit {
      width: 550px !important;
      .user-type-modal--left {
        width: 100%;
      }
      .user-type-modal__buttons {
        width: 100%;
        justify-content: space-between;
      }
    }
    &--instagram-tags {
      width: 1100px !important;
      .user-type-modal--left {
        width: 100%;
      }
      .user-type-modal__buttons {
        width: 100%;
        justify-content: space-between;
      }
      .customize-field {
        margin-top: 20px;
      }
      .modal__top--title {
        text-align: center;
      }
      .user-type-modal__buttons {
        margin-top: 0;
        .btn-wrapper {
          width: calc(33% - 20px);
        }
        .btn {
          width: 100%;
        }
      }
    }
    &--tags {
      min-width: 800px;
      max-height: 600px !important;
    }
    &--address {
      min-width: 600px;
    }
    &--top {
      @media (min-width: 1024px) {
        top: 40%;
      }
    }
    &--loginManage {
      min-width: 500px;
      @media (min-width: 1024px) {
        width: 384px;
      }
    }
    @include md {
      max-height: 100%;
      border-radius: 8px;
      box-shadow: 0px 3px 40px #00000029;
      padding: 37px 40px;
      width: unset;
      height: unset;
    }
    &--delete .modal-remove-buttons--content {
      max-width: 100%;
    }
    &--add {
      min-width: 400px;
    }
  }
  &__top {
    font-family: "Montserrat";
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &--description {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 29, 61, 0.3);
      max-width: 300px;
      text-align: left;
      margin-bottom: 24px;
    }
    &--title {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 35px;
      font-weight: 700;
      color: $color__darkBlue;

      &--center {
        text-align: center;
      }
    }
  }
  &__paragraph {
    &--ligth {
      font-size: 12px;
      font-weight: 700;
      color: rgba(0, 29, 61, 0.3);
      padding: 8px 0;
    }
    &--dark {
      font-size: 12px;
      font-weight: 700;
      color: #001d3d;
      padding: 8px 0;
    }
    &--wrapper {
      display: flex;
      justify-content: center;
      p {
        margin-right: 5px;
      }
    }
    &--cursor {
      cursor: pointer;
    }
  }
}

.modal-buttons {
  .btn {
    width: 100%;
    margin: 12px 0;
  }
}

.modal-remove-buttons {
  &--wrapper {
    display: flex;
    justify-content: center;
  }
  &--content {
    margin: 40px 0 10px;
    max-width: 350px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 140px;
    }
  }
}

.modal--check-mail {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  .svg {
    max-width: 200px;
    max-height: 200px;
  }
}

.modal-enter {
  animation: modalEnterAnimation 0.3s forwards;
}

.modal-exit {
  animation: modalExitAnimation 0.3s forwards;
}

@keyframes modalEnterAnimation {
  from {
    opacity: 0;
    transform: scale(0.99) translate(-50%, -50%);
  }
  to {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes modalExitAnimation {
  from {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
  to {
    opacity: 0;
    transform: scale(0.99) translate(-50%, -50%);
  }
}
