.delivery-page {
  .top {
    &__header {
      .btn-wrapper {
        margin-left: 20px;
      }
    }
  }
  .content {
    width: 100%;
    max-width: 1400px;
    display: flex;
    &__wrapper {
      margin: 30px;
      height: calc(100vh - 124px);
      display: flex;
      justify-content: center;
    }
  }
}
