.bookingHarmonogram {
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    .modal--h3 {
      text-align: left;
      font-size: 20px;
      margin-bottom: 45px;
    }
  }
  &--date {
    font-weight: 600;
    margin-top: -20px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  &__list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }
  &__item {
    border: 1px solid #cecece;
    padding: 8px;
    border-radius: 2px;
    display: flex;
    &--image {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &--caption {
      font-size: 12px;
      font-weight: 600;
    }
    &--booking {
      display: flex;
      margin-left: auto;
      flex-direction: column;
      input {
        margin-top: 0 !important;
        text-align: center;
        width: 180px;
      }
    }
  }
}

.bookingHarmonogramDate {
  .react-datepicker-popper {
    transform: translate3d(431.5px, 33px, 0px) !important;
  }
}
.modal__inner--bookingHarmonogram {
  min-width: 800px;
  min-height: 350px;

  .react-datepicker__header--time {
    display: none;
  }
  .react-datepicker__time {
    background-color: unset !important;
    margin-top: 30px !important;
  }
  .react-datepicker__time-container {
    border-left: 0;
  }

  .btn-wrapper {
    min-width: 200px;
    .btn {
      width: 100%;
    }
  }
}
