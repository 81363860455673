@import "../../../components/settings-dots/settings-dots.scss";

.event-elements {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.event-element {
  box-shadow: var(--box-shadow);
  position: relative;
  transition: 0.3s;
  background-color: var(--primary-color-light);
  padding: 20px;
  max-width: 440px;
  border-radius: 10px;
  display: flex;
  flex: 1 1;
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  &__image {
    width: 150px;
    border-radius: 10px;
  }
  &__descript {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    // .settings-dots {
    //   margin-top: auto;
    //   &__wrapper {
    //     display: flex;
    //     justify-content: flex-end;
    //   }
    // }
  }
  &__heading {
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  &__info {
    margin-top: 10px;
  }
  &__date,
  &__crafts,
  &__count {
    font-size: 12px;
    color: hsla(223, 35%, 13%, 0.5);
    font-weight: 600;
    line-height: 16px;
    span {
      margin-left: 5px;
      color: hsla(223, 35%, 13%, 0.7);
    }
  }
  &--orange {
    border-color: #eaa659;
  }
  &--green {
    &:after {
      content: "";
      width: 12px;
      height: 12px;
      background: var(--client--color);
      position: absolute;
      top: 8px;
      right: 8px;
      border-radius: 10px;
    }
  }
  &--red {
    border-color: #ea5959;
  }
  &--gray {
    border-color: #c4c4cc;
  }
  &--onclick {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  .settings-dots {
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &--disabled {
      pointer-events: none;
    }
  }
}
.event-elements-pagination__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

// .events-table {
//   width: 100%;
//   height: 100%;
//   border-collapse: separate;
//   border-spacing: 0 20px;

//   transition: 0.3s;
//   &--hiden {
//     transition: 0.3s;
//     max-height: 0px;
//     opacity: 0;
//   }
//   &--expand {
//     transition: 0.3s;
//     max-height: 1000px;
//     opacity: 1;
//   }
//   &__wrapper {
//     margin-top: -20px;
//     margin-bottom: 60px;
//   }
//   &__head {
//     margin-bottom: 20px;
//     th {
//       padding: 17px 14px;
//       text-align: left;
//       background-color: var(--primary-color-light);
//       font-size: 14px;
//       font-weight: 600;
//       &:first-child {
//         border-radius: 8px 0 0 8px;
//       }
//       &:nth-last-child(2) {
//         border-radius: 0 8px 8px 0;
//         width: 20px;
//       }
//     }
//   }
//   tr {
//     border-spacing: 0 20px;
//   }
//   &--onclick {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: calc(100% - 40px) !important;
//     height: 100%;
//     background-color: transparent !important;
//     cursor: pointer;
//   }
//   &__single {
//     margin-bottom: 20px;
//     width: 100%;
//     box-shadow: var(--box-shadow);
//     position: relative;
//     transition: 0.3s;
//     .settings-dots {
//       padding-right: 0;
//     }
//     &:hover {
//       box-shadow: var(--box-shadow--active);
//       transition: 0.3s;
//     }
//     td {
//       font-weight: 500;
//       padding: 17px 14px;
//       text-align: left;
//       background-color: var(--primary-color-light);
//       font-size: 14px;
//       &:first-child {
//         border-radius: 8px 0 0 8px;
//         max-width: 300px;
//       }
//       &:last-child {
//         border-radius: 0 8px 8px 0;
//         width: 20px;
//       }
//     }
//   }
// }

// .events-table-badge {
//   padding: 8px 15px;
//   border-radius: 12px;
//   min-width: 174px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: var(--primary-color-light);
//   &__wrapper {
//     width: 100%;
//     // display: flex;
//     // justify-content: center;
//   }
// &--orange {
//   background-color: #eaa659;
// }
// &--green {
//   background-color: var(--client--color);
// }
// &--red {
//   background-color: #ea5959;
// }
// }

// .events-table-pagination {
//   &__wrapper {
//     display: flex;
//     justify-content: center;
//   }
// }
