.headerMain {
  &__wrapper {
    height: 40px;
    display: flex;
    justify-content: center;
    position: sticky;
    z-index: 99;
    padding: 0 25px 0 135px;
    // width: 1400px;
    width: 100%;
    box-shadow: var(--box-shadow);
  }
  &__content {
    // max-width: 1360px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .basket {
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
        path {
          stroke: var(--sidebar-color);
        }
      }
    }
    .watch {
      margin-left: auto;
      margin-right: 10px;
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
        path {
          fill: var(--sidebar-color);
        }
      }
    }
    .account {
      cursor: pointer;
      margin-right: 20px;
      @media (max-width: 756px) {
        margin-right: 0;
      }
      svg {
        height: 18px;
        width: 18px;
        path {
          fill: var(--sidebar-color);
        }
      }
    }
  }
  &--icon {
    margin: 0 8px;
  }
  &--icons {
    display: flex;
    align-items: center;
  }
  &__items {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__item {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    position: relative;
    &--link {
      text-decoration: none;
      color: rgba(255, 255, 255, 0.4);
      font-size: 14px;
      font-weight: 600;
      padding: 0 10px;
      &--first {
        padding-left: 0;
      }
    }
    &--divider {
      position: absolute;
      left: -5px;
      line-height: 20px;
      width: 1.7px;
      height: 20px;
      &--green {
        background-color: rgba(59, 155, 35, 0.6);
      }
      &--blue {
        background-color: rgba(31, 14, 189, 0.6);
      }
      &--yellow {
        background-color: rgba(206, 226, 27, 0.6);
      }
      &--red {
        background-color: rgba(202, 33, 11, 0.6);
      }
      &--white {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  &--my-account {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin-left: 8px;
    margin-bottom: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 5px;
    &--active {
      transform: rotate(180deg);
    }
    &__wrapper {
      position: relative;
    }
    svg {
      margin-left: 6px;
      path {
        fill: #fff;
      }
    }
  }
}
.account-menu {
  position: absolute;
  overflow: hidden;
  top: 28px;
  width: 200px;
  left: -91px;
  box-shadow: var(--box-shadow);
  // border-radius: 4px;
  border-top: 0;
  background-color: #fff;
  max-height: 0;
  transition: 0.2s;
  &--active {
    max-height: 300px;
    opacity: 1;
    transition: 0.3s;
    border: 0.1px solid var(--border-line);
  }
  &--item {
    padding: 13px 30px 13px 40px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    &--text {
      color: var(--main-color);
      &--red {
        color: var(--price-color);
      }
    }
    .svg {
      position: absolute;
      left: 13px;
      top: auto;
      height: 14px;
      width: 14px;
      &--user path,
      &--product path,
      &--category path {
        fill: var(--primary-color);
      }
      &--delivery {
        width: 17px;
        height: 17px;
        path {
          fill: var(--primary-color);
        }
      }
      &--onof path {
        fill: var(--price-color);
      }
      &--settings path {
        stroke: var(--primary-color);
      }
    }
    &:hover {
      background-color: rgb(238, 238, 238);
    }
  }
}
