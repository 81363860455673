// .settings-page {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   &__top {
//     margin-top: 40px;
//   }
//   .btn-wrapper {
//     margin-top: auto;
//   }
// }

.profile-tile {
  margin: 20px;
  box-shadow: var(--box-shadow);
  padding: 20px;
  background-color: #fff;
  border-radius: 14px;
  transition: var(--tran-03);
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: var(--tran-03);
    .profile-tile--edit,
    .profile-tile--delete,
    .profile-tile--add {
      transition: var(--tran-03);
      opacity: 1;
    }
  }
  .slick-dots {
    margin-bottom: 20px;
    li button::before {
      font-size: 10px;
    }
    li.slick-active button::before {
      color: var(--main-color);
    }
    li button:hover {
      color: var(--primary-color);
    }
  }
  &--add {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 0;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
      transition: var(--tran-03);
    }
  }
  &--edit {
    position: absolute;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    opacity: 0;
    transition: var(--tran-03);
    &:hover {
      transition: var(--tran-03);
      transform: scale(1.2);
    }
  }
  &--delete {
    position: absolute;
    bottom: 18px;
    right: 45px;
    cursor: pointer;
    opacity: 0;
    transition: var(--tran-03);
    &:hover {
      transition: var(--tran-03);
      transform: scale(1.2);
    }
  }
  &__address {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &__heading {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    .svg {
      margin-right: 15px;
      width: 30px;
      height: 30px;
    }
    .svg--lock {
      width: 25px;
      height: 25px;
      margin-left: 3px;
    }
  }
}
.address {
  &__content {
    padding: 10px 20px 10px 45px;
    position: relative;
  }
  &__line {
    line-height: 18px;
    font-size: 14px;
    font-weight: 500;
  }
}

.address-form {
  display: flex;
  column-gap: 30px;
}
