.product-page {
  margin-top: 20px;
  .top {
    display: flex;
    @media (max-width: 756px) {
      flex-direction: column;
    }
  }
  .right {
    margin-left: 20px;
    margin-top: 5px;
    @media (max-width: 756px) {
      margin-left: 0;
    }
  }
  .product-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &__wrapper {
      max-width: 50%;
      max-height: 400px;
      flex-shrink: 0;
      @media (max-width: 756px) {
        margin: 0 auto 20px auto;
      }
    }
  }
  .product-detail {
    .name {
      font-size: 20px;
      font-weight: 600;
      line-height: 130%;
    }
  }
  .product-price {
    padding-bottom: 20px;
    font-weight: 600;
    letter-spacing: 0.7px;
    color: var(--price-color);
    border-bottom: 1px solid var(--border-line);
  }
  .product-descript {
    font-size: 16px;
    line-height: 130%;
    padding: 20px 0;
    border-bottom: 1px solid var(--border-line);
  }
  .product-actions {
    padding: 20px 0;
    border-bottom: 1px solid var(--border-line);
    &--top {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    &__watch {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      max-width: 98px;
      justify-content: center;
      .svg {
        height: 14px;
        margin-left: 5px;
      }
      .svg--heart-active {
        height: 16px;
        width: 18px;
      }
    }
    .input-counter {
      margin-right: 20px;
      box-shadow: 0px 4px 6px rgba(1, 36, 68, 0.14);
    }
  }
}
