.page-wrapper {
  min-height: 100%;
  min-width: 100%;
  background-color: var(--first-color);
  display: flex;
  flex-direction: column;
  &--dashboard {
    background-color: unset;
  }
}

.page__dashboard {
  padding-left: 88px;
  @media (max-width: 755px) {
    padding-left: 0;
  }
}

.page {
  &--heading {
    font-weight: 600;
    font-size: 22px;
  }
  &__build {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    &--background {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    &--header {
      z-index: 9;
      color: #fff;
      text-transform: uppercase;
      h1 {
        font-size: 50px;
        font-weight: 900;
        text-align: center;
      }
      h2 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin: 15px 0;
      }
    }
  }
}
