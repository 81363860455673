@import '../../sass/variables';
iframe {
    display: none;
    height: 0px;
}

// box-shadow: rgba(0, 0, 0, 0.05) 6px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

$menu__item--unactive: rgba( 255, 255, 255, 0.4);
$menu__item--active: rgba( 255, 255, 255, 0.9);
$menu-items: 5;
$width:(100/$menu-items) * 1%;
$widthLamp: ($menu-items) * 3%;
$widthLamp300: ($menu-items) * 2.7%;
$widthLamp350: ($menu-items) * 3%;
$widthLamp400: ($menu-items) * 3.2%;
$widthLamp550: ($menu-items) * 3.37%;
$widthLamp650: ($menu-items) * 3.45%;
$transition-speed: 0.4s;
$indicator-color: #fff;
$menu-items-loop-offset: $menu-items - 1;


@keyframes addGrow {
    0% {
        transform: scale(1);
    }   
    100% {
    transform: scale(1.5);        
    }    
  }
  @keyframes addBlink {
    0% {
        fill: rgba( 255, 255, 255, 0.6 )
    }    
    100% {
        fill: $menu__item--active
    }    
  }  
  @keyframes blinkLamp {
    0% {
        border-bottom: 100px solid rgba(255, 255, 255, 0.1);
    }    
    50% {
        border-bottom: 100px solid rgba(255, 255, 255, 0.03);
    }    
    100% {
        border-bottom: 100px solid rgba(255, 255, 255, 0.1);
    }    
  }

.bottom-nav {
    background-color: $color__black;
    display: flex;
    height: 69px;
    width: 800px;
    &__item {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        width: $width;
        &--icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &--icon path{
            fill: $menu__item--unactive;
        }
        .svg--home path {
            fill: transparent;
            stroke: $menu__item--unactive;
        }        
        .svg--chat {
            width: 20px;
            height: 20px;
            path {
                fill: transparent;
                stroke: $menu__item--unactive;
            }
        }
    }
    &__item--active {
        .svg path {
            fill: $menu__item--active;
            transition: 0.4s;
        }
        .svg--home path {
            fill: transparent;
            stroke: $menu__item--active;
        }        
        .svg--chat path {
            fill: transparent;
            stroke: $menu__item--active;
        }
    }
    &__item-add {
        svg {
            width: 40px;
            height: 40px;
            padding: 10px;
        }
        &--active svg {
            animation: 1s addGrow ease;
        }        
        &--active svg path {
            animation: 1s addBlink ease;
        }
    }
}

@for $i from 1 through $menu-items-loop-offset {

    .bottom-nav__item:nth-child(#{$i}).bottom-nav__item--active ~ .bottom-nav__item:last-child:after {
      left:($width*$i)-$width;
    }
    .bottom-nav__item:nth-child(#{$i}).bottom-nav__item--active ~ .bottom-nav__item:last-child:before {
      left:($width*$i)-$width;
    }
}

.bottom-nav {
    position: relative;
    z-index: 0;
    border-top: 1px solid rgba(128, 128, 128, 0.435);
    .bottom-nav__item {
        &:last-child {
            &:after, &:before {
                content: '';
                display: block;
                position: absolute;
                transition: left #{$transition-speed} ease;
            }
            &:after {
                background: $indicator-color;
                border-radius: 4px;
                height: 5px;
                left: 0px;
                top: - 0.4px;
                width: $width;
                z-index: -1;
            }
            &:before {
                height: 50px;
                left: 0px;
                top: 4px;
                border-bottom: 100px solid rgba(255, 255, 255, 0.1);
                border-left: 11px solid transparent;
                border-right: 16px solid transparent;
                height: 0;
                width: $widthLamp;
                z-index: -1;
                animation: 1s blinkLamp ease infinite;
                @media(min-width: 200px) {
                    width: $widthLamp300;
                    border-right: 11px solid transparent;
                }                   
                @media(min-width: 350px) {
                    width: $widthLamp350;
                }                 
                @media(min-width: 400px) {
                    width: $widthLamp400;
                } 
                @media(min-width: 550px) {
                    width: $widthLamp550;
                }                
                @media(min-width: 650px) {
                    width: $widthLamp650;
                }
            }

        }
        &:last-child {
            &.bottom-nav__item--active {
              &:after{
                left: 100%-$width !important;
              }              
              &:before{
                left: 100%-$width !important;
              }
            }        
          }
    }
}

.bottom-nav--desk {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 100%;
        top: -1px;
        width: 9999999px;
        height: calc(100% + 1px);
        background-color: $color__black;
    } 
    &:before {
        content: '';
        position: absolute;
        right: calc(100% - 1px);
        top: -1px;
        width: 9999999px;
        height: calc(100% + 1px);
        background-color: $color__black;
    }
}