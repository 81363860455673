.article-page {
  &__top {
    display: flex;
    gap: 20px;
  }
  &__mid {
    display: flex;
    gap: 20px;
  }

  &__mid-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100% - 400px);
  }

  &__mid-right {
    width: 100%;
    max-width: 400px;
  }

  &__mid-heading {
    font-size: 20px;
    font-weight: 600;
    padding: 20px 10px 0 20px;
  }
  &__mid-data {
    padding: 8px 0 20px 20px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
  }
  &__mid-paragraph {
    padding: 0 20px;
    font-size: 16px;
    line-height: 22px;
  }
  &__mid-list {
    padding: 20px;
  }
  &__mid-list-item {
    font-size: 16px;
    line-height: 20px;
    padding: 5px 0;
  }
}

.article-page-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  &__wrapper {
    width: 100%;
    max-width: calc(100% - 400px);
    max-height: 400px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    &:hover {
      transition: 0.3s;
      box-shadow: var(--box-shadow--active);
    }
  }
}

.article-page-author {
  &__heading {
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px;
    position: relative;
    color: #161d2e;
    margin-bottom: 20px;
    display: flex;
    min-height: 34px;
    align-items: center;
  }
  &__wrapper {
    box-shadow: var(--box-shadow);
    display: flex;
    max-width: 400px;
    width: 100%;
    flex-direction: column;
  }
}

.article-page-author-box {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color-light);
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  &__name {
    margin-top: 15px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
  &__avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  &__avatar-wrapper {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin: 0 auto;
  }
  &__top {
    width: 100%;
    display: flex;
    padding: 30px 20px 0 20px;
    flex-direction: column;
  }
  &__bottom {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  &__button {
    padding: 25px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      box-shadow: var(--box-shadow--active);
      transition: 0.3s;
    }
    p {
      font-weight: 500;
      font-size: 16px;
    }
  }
  &__crafts {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    transition: 0.3s;
    box-shadow: var(--box-shadow--active);
  }
  &__social {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  &__single-social {
    cursor: pointer;
    svg {
      width: 40px;
      height: 40px;
      path {
        fill: #161d2e;
      }
    }
  }
}
