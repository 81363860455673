.inactive {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  height: calc(100vh);
  background-color: var(--third-color);
  .svg {
    max-width: 300px;
    max-height: 300px;
    margin: 0 auto;
  }
  .inactive {
    height: auto;
  }
  &--content {
    max-width: 500px;
    display: flex;
    flex-direction: column;
  }
  &--header {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    line-height: 36px;
    margin-top: 10px;
  }
  &--paragraph {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 24px;
    margin-top: 10px;
  }
  .btn {
    max-width: 300px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    .svg {
      top: 2px;
    }
  }
  &--form--wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .regular-field {
      max-width: 350px;
      width: 100%;
      margin: 20px auto;
    }
  }
}
