.single-event {
  width: 100%;
  display: flex;
  margin-bottom: 200px;
  &--top {
    display: flex;
  }
  &__content {
    background-color: var(--primary-color-light);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  &__image-wrapper {
    max-width: 300px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    flex-shrink: 0;
  }
  &__heading {
    font-size: 20px;
    font-weight: 600;
    padding: 0 10px 0 0;
  }
  &__descript {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    font-size: 500;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__date,
  &__crafts,
  &__count {
    font-size: 15px;
    color: hsla(223, 35%, 13%, 0.5);
    font-weight: 600;
    line-height: 24px;
    span {
      margin-left: 5px;
      color: hsla(223, 35%, 13%, 0.7);
    }
  }
  &__button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  // &__count {
  //   display: flex;
  //   flex-direction: column;
  //   span {
  //     margin-left: 0;
  //     margin-top: 10px;
  //   }
  // }
  // &__count {
  //   display: flex;
  //   flex-direction: column;
  //   font-size: 15px;
  //   color: hsla(223, 35%, 13%, 0.5);
  //   font-weight: 600;
  // }
}

.single-event-list {
  display: flex;
  flex-direction: column;
}

.single-event-list-item {
  font-size: 16px;
  margin: 10px 0;
  display: flex;
  line-height: 24px;
  &--number {
    margin-right: 10px;
    font-size: 18px;
    font-weight: 500;
  }
}
