.crafts-new-user {
  background-color: rgba(19, 19, 19, 0.6);
  padding: 12px 20px;
  min-width: 280px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  box-shadow: var(--box-shadow--white--active);
  &__wrapper {
    position: absolute;
    bottom: 20px;
    left: 20px;
    // display: flex;
    // flex-direction: column;
  }
  &__heading {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
    margin: 10px;
    color: var(--second-color);
  }
  .slick-slider,
  .slick-list {
    max-width: 250px;
  }
}
