@import "./components/my-account.scss";

.menu-top {
  &--account {
    margin-left: auto;
  }
  &__wrapper {
    padding-top: 40px;
    @media (max-width: 900px) {
      padding-top: 0;
    }
  }
  &__content {
    width: 100%;
    padding: 19px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
    &:before,
    &:after {
      content: "";
      display: flex;
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      background-color: rgba(255, 255, 255, 0.08);
    }
    &:before {
      top: -1px;
    }
    &:after {
      bottom: -1px;
    }
    @media (max-width: 900px) {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      padding: 15px 20px 15px 20px;
    }
    &--desktop {
      @media (max-width: 900px) {
        display: none;
      }
    }
    &--mobile {
      @media (min-width: 900px) {
        display: none;
      }
    }
  }
  &__list {
    display: flex;
    padding: 0 30px;
    align-items: center;
    @media (max-width: 900px) {
      display: none;
    }
  }
  &__item {
    margin-right: 20px;
    color: var(--second-color);
    text-decoration: none;
    @media (max-width: 900px) {
      font-size: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
    &--login {
      margin-left: auto;
      .btn {
        min-width: 130px;
      }
    }
    &--skeleton {
      margin-left: auto;
    }
    .btn .svg {
      width: 36px;
      height: 16px;
      path {
        fill: #fff;
      }
    }
  }
  .hamburger {
    cursor: pointer;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}
.menu-top-mobile {
  position: relative;
  @media (min-width: 900px) {
    display: none;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 65px);
    position: absolute;
    width: 100%;
    background-color: var(--first-color);
    z-index: 9;
    right: -900px;
    transition: 0.3s;
    &--opened {
      right: 0;
      transition: 0.3s;
    }
    &--list {
      margin-top: 66px;
      text-align: center;
    }
    &--item {
      margin: 24px 0;
      .btn-wrapper {
        justify-content: center;
      }
    }
  }
}
.mobile-menu {
  @media (min-width: 900px) {
    display: none;
  }
}

.menu-top-skeleton {
  margin-left: auto;
  display: flex;
  width: 120px;

  .svg {
    height: 35px;
    width: 35px;
    path {
      fill: #3f3f3f;
    }
  }
}
