.dashboard-section-heading {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  &--margin {
    padding: 20px;
  }
  .label {
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px;
    position: relative;
    color: #161d2e;
  }
  .btn-wrapper {
    margin-left: auto;
  }
}
