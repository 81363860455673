.featured-products {
  position: relative;
  height: 100%;
  margin-top: -1px;
  padding: 0 40px;
  @media (max-width: 600px) {
    padding: 0 20px;
  }
  .products {
    .actions {
      flex-direction: row-reverse;
      .btn-wrapper:first-child {
        p {
          display: none;
        }
        .svg {
          margin: 0;
        }
      }
    }
  }
  .slick-arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: var(--box-shadow--active);
    background-color: #fff;
    z-index: 9;
    transition: 0.3s;
    &:hover,
    &:focus {
      background-color: #fff;
      transition: 0.3s;
    }
  }
  .slick-prev {
    left: -15px;
    &:before {
      // content: url('../../files/chevrone.svg');
      transform: rotate(180deg);
    }
  }
  .slick-next {
    right: -15px;
    &:before {
      // content: url('../../files/chevrone.svg');
    }
  }
  .product {
    max-width: 450px;
    min-width: 220px;
  }
  .wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
