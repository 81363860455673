.delivery {
  &--list {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: var(--primary-color-light);
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    border-radius: 8px;
    margin-bottom: auto;
    &:hover {
      box-shadow: var(--box-shadow--active);
    }
  }
  &--item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 30, 61, 0.9);
    border-radius: 10px;
    padding: 12px 15px 12px 15px;
    margin: 20px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &__label {
      color: #fff;
      font-weight: 500;
      margin-left: 20px;
      margin-right: 15px;
    }
    &__icon {
      width: 25px;
      height: 25px;
      path {
        fill: #fff;
      }
      &--wrapper {
        display: flex;
        align-items: center;
      }
    }
    &__price {
      color: #fff;
      font-weight: 500;
      margin-left: auto;
      margin-right: 15px;
    }
    &--actions {
      display: flex;
      margin: 0 10px;
    }
    &--action {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .svg {
        width: 15px;
        height: 15px;
        path {
          fill: #fff;
        }
      }
      .svg--delivery--delete {
        width: 25px;
        height: 25px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
      &:nth-child(1) {
        .svg path {
          fill: var(--price-color);
        }
      }
    }
  }
}

.delivery__form {
  margin-top: 20px;
}
