.curves-section {
  background-color: var(--third-color);
  display: flex;
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  padding: 200px 0;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    padding: 140px 0;
    margin-top: -2px;
  }
  @media (max-width: 600px) {
    padding: 100px 0;
    margin-top: -2px;
  }
  &__content {
    max-width: 1200px;
    width: 100%;
    margin: 0 20px 100px 20px;
    @media (max-width: 900px) {
      margin-bottom: 40px;
    }
  }
  &--top,
  &--bottom {
    left: 0;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  &--top {
    top: 0;
    @media (max-width: 600px) {
      top: -1px;
    }
  }
  &--bottom {
    bottom: -1px;
  }
}
