.top-bar {
  align-items: center;
  display: flex;
  height: 56px;
  max-width: 1440px;
  position: relative;
  width: 100%;
  z-index: 9;
  &__wrapper {
    width: 100%;
    border-bottom: 1px solid #ececec;
    box-shadow: 0 1px 4px rgba(64, 64, 64, 0.1);
    display: flex;
    justify-content: center;
    &:hover {
      box-shadow: var(--box-shadow);
      transition: 0.3s;
    }
  }
  &--right-side {
    display: flex;
    margin-left: auto;
  }
  &--more {
    position: relative;
  }
}

.profile-top-bar {
  border: 1px solid #ececec;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  max-width: 40px;
  transition: 0.7s;
  transition-delay: 0.1s;
  &:hover {
    width: auto;
    max-width: 200px;
    box-shadow: 0 1px 4px rgba(64, 64, 64, 0.1);
  }
  &--text {
    display: flex;
    flex-direction: column;
    padding: 7px 12px;
  }
  &--name {
    display: block;
    font-weight: 500;
    font-size: 14px;
  }
  &--profile {
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
    text-align: right;
    text-decoration: underline;
  }
}
