@import "./components/new-user.scss";
@import "./components/crafts-category.scss";
@import "./components/crafts-articles.scss";
@import "./components/crafts-events.scss";

.crafts {
  &__section {
    background-color: #161d2e;
    padding: 100px 0;
  }
  &__background {
    width: 100%;
    height: 100%;
    max-height: 500px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .featured-products {
    padding: 0;
    .product {
      margin: 10px;
    }
    .heading {
      margin: 40px 0 35px 0;
    }
  }
  .tiles {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    @media (max-width: 600px) {
      row-gap: 20px;
    }
    .big {
      width: calc(60% - 10px);
      flex-shrink: 0;
      height: 400px;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
    .right {
      width: calc(40% - 10px);
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;

      p {
        font-size: 24px;
        font-weight: 600;
        color: var(--main-color);
        margin-bottom: auto;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: var(--main-color);
      }
      .descript {
        display: flex;
        flex-direction: column;
        max-width: 50%;
        width: 100%;
        padding: 24px 0 24px 24px;
        &--top {
          @media (max-width: 800px) {
            display: none;
          }
        }
        @media (max-width: 800px) {
          max-width: 100%;
          padding: 0;
        }
      }
      .forum {
        cursor: pointer;
      }
      @media (max-width: 600px) {
        width: 100%;
        flex-wrap: nowrap;
        column-gap: 20px;
      }
      @media (max-width: 450px) {
        width: 100%;
        flex-wrap: wrap;
      }
    }
    .small {
      width: 100%;
      height: calc(200px - 10px);
      align-self: flex-start;
      justify-self: flex-start;
      display: flex;
      .svg {
        height: 100%;
        width: 100%;
        @media (max-width: 800px) {
          margin-top: 20px;
        }
      }
      @media (max-width: 600px) {
        height: calc(300px - 10px);
      }
      @media (max-width: 800px) {
        flex-direction: column;
        padding: 24px;
      }
    }
  }
  .tile {
    padding: 0;
    border-radius: 8px;
    // background-color: var(--body-color);
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    &:hover {
      box-shadow: var(--box-shadow--active);
      transition: 0.3s;
    }
  }
  &__behind-articles {
    height: 400px;
    width: 100%;
    background-color: rgba(22, 29, 46, 0.98);
    margin-top: -3px;
    img {
      opacity: 0.05;
    }
  }
}

.crafts .products {
  margin-top: 50px;
  margin-bottom: 300px;
  .title {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .item {
    padding: 10px;
    transition: var(--tran-05);
    .btns {
      display: flex;
      justify-content: space-between;
      .favorites {
        transition: var(--tran-04);
        opacity: 0;
      }
    }
    .btn {
      &--cart svg {
        width: 16px;
      }
      margin-top: 10px;
      &--icon--right {
        margin: 0;
      }
    }
    &--text {
      margin-top: 10px;
      z-index: 9;
      position: relative;
    }
    &--content {
      padding: 10px;
      padding-bottom: 20px;
      position: relative;
      transition: var(--tran-05);
      &:hover {
        .favorites {
          opacity: 1;
          transition: var(--tran-04);
        }
      }
      &:hover:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid var(--body-color);
        border-radius: 8px;
        box-shadow: var(--box-shadow);
        transition: var(--tran-05);
        z-index: 0;
      }
    }
  }
  .image {
    max-width: 100%;
    border-radius: 4px;
    &__wrapper {
      border-radius: 4px;
    }
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
    cursor: pointer;
    height: 50px;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var();
  }
  .price {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    text-align: right;
  }
}
