.date-picker-field {
  line-height: 20px;
  padding: 10px 15px 10px 14px;
  border-radius: 4px;
  margin: 16px 0 0;
  cursor: pointer;
  font-family: Inter;
  outline-style: none;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(187, 187, 187, 0.02);
  font-size: 14px;
  width: 100%;
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__input {
    &--error {
      border: 1px solid #e20d0d !important;
    }
  }
}
.react-datepicker__day--disabled {
  background-color: #cecece;
  pointer-events: none;
}
// react-datepicker__day--outside-month
.react-datepicker {
  border: 0 !important;
  padding: 10px 14px;
  background-color: var(--primary-color-light) !important;
  box-shadow: var(--box-shadow--active);
  &__day {
    color: rgba(0, 30, 61, 1) !important;
    font-size: 500 !important;
    &--outside-month {
      color: rgba(0, 30, 61, 0.2) !important;
    }
    // &--today {
    //   &:hover {
    //   }
    // }
    // &--selected {
    // }
  }
  &__current-month {
    margin-bottom: 20px !important;
    color: rgba(0, 30, 61, 0.7) !important;
  }
  &__navigation {
    &--previous {
      left: 20px !important;
      top: 10px !important;
      color: rgba(0, 30, 61, 0.7);
    }
    &--next {
      top: 10px !important;
      right: 20px !important;
      color: rgba(0, 30, 61, 0.7);
    }
  }

  &__navigation-icon::before {
    border-color: rgba(0, 30, 61, 0.6) !important;
  }
}

.react-datepicker__month-container {
  .react-datepicker {
    &__header {
      background-color: unset;
      border: unset;
    }
  }
}
