.favorites-product {
  border: 1px solid var(--border-line);
  border-radius: 8px;
  display: flex;
  margin: 30px 20px;
  padding: 11px;
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: var(--box-shadow--active);
  }
  @media (max-width: 600px) {
    flex-direction: column;
    padding-top: 30px;
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    @media (max-width: 600px) {
      width: 50%;
      height: auto;
    }
    &--wrapper {
      cursor: pointer;
      border-radius: 4px;
      width: 170px;
      height: 140px;
      overflow: hidden;
      flex-shrink: 0;
      @media (max-width: 600px) {
        width: 100%;
        display: flex;
        height: auto;
        justify-content: center;
      }
    }
  }
  .mid {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    padding-top: 4px;
    max-width: 400px;
    @media (max-width: 900px) {
      max-width: calc(100% - 194px);
    }
    @media (max-width: 600px) {
      width: 100%;
      max-width: 100%;
    }
  }
  &--name {
    line-height: 130%;
    font-size: 18px;
    font-weight: 500;
    padding-right: 10px;
    color: var(--main-color);
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  &--count {
    padding-bottom: 6px;
    font-size: 12px;
    line-height: 140%;
    margin-top: auto;
    span {
      margin-left: auto;
      font-weight: 600;
    }
  }
  &--price {
    font-size: 22px;
    font-weight: 500;
    line-height: 140%;
    padding: 5px 0;
    color: var(--main-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .right {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px;
    flex-shrink: 0;
    @media (max-width: 600px) {
      width: 100%;
      flex-direction: row;
    }
  }
}
