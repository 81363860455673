.tile {
  width: 100%;
  height: 150px;
  // background-color: $color__lightDarkBlue;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    transition: 0.3s;
  }
  &--add-new {
    display: flex;
    align-items: center;
    justify-content: center;
    &--icon {
      cursor: pointer;
      height: 40px;
      width: 40px;
    }
  }
}
