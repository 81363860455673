.svg {
  // &--phone {
  //   width: 16px;
  // }
  // &--facebook path {
  //   fill: rgb(59, 89, 152);
  // }
  // &--pinterest path {
  //   fill: rgb(189, 8, 28);
  // }
  &--heart-active {
    path {
      fill: #003566;
    }
  }
  &--star {
    &--full path {
      fill: var(--primary-color);
    }
    &--half path {
      fill: var(--primary-color);
    }
  }
  &--hamburger {
    cursor: pointer;
    height: 16px;
    width: 16px;
    path {
      fill: #fff;
    }
  }
  &--loading {
    margin: auto;
    background: none;
    display: block;
    shape-rendering: auto;
    animation-play-state: running;
    animation-delay: 0s;
    path {
      animation-play-state: running;
      animation-delay: 0s;
    }
  }
  &--spiner-loading {
    margin: auto;
    background: none;
    display: block;
    shape-rendering: auto;
  }
}
