.counters {
  display: flex;
  gap: 10px;
  margin-left: 10px;
  .counter {
    min-height: 34px;
    padding: 4px 6px 4px 10px;
    border-radius: 12px;
    border: 3px solid;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: 0.3s;
    overflow: hidden;
    box-shadow: var(--box-shadow);
    &:hover {
      background-color: var(--primary-color-light);
      transition: 0.3s;
      transition-delay: 0.5s;
      max-width: 400px;
      box-shadow: var(--box-shadow--active);
      .counter-label {
        max-width: 400px;
        opacity: 1;
        transition: 0.3s;
        transition-delay: 0.5s;
        z-index: 1;
      }
    }
    &--fit {
      border: 1px solid !important;
    }
    &--client {
      border-color: var(--client--color);
    }
    &--craftsman {
      border-color: var(--craftsman--color);
    }
    &--distributor {
      border-color: var(--distributor--color);
      &--bigger {
        width: 70px;
      }
    }
    &--editor {
      border-color: var(--editor--color);
    }
  }
  .counter-wrapper {
    width: 40px;
    height: 40px;
    position: relative;
    &--bigger {
      width: 50px;
    }
    &:first-child {
      z-index: 9;
    }
    &:nth-child(2) {
      z-index: 8;
    }
    &:nth-child(3) {
      z-index: 7;
    }
    &:nth-child(4) {
      z-index: 6;
    }
    .counter {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .counter-label-number {
    align-items: center;
    justify-content: center;
    font-weight: 600;
    display: flex;
    height: 100%;
    color: #161d2e;
    margin-right: 4px;
  }

  .counter-label {
    font-size: 14px;
    font-weight: 600;
    color: #161d2e;
    max-width: 0px;
    opacity: 0;
    transition: max-width 0.3s, opacity 0.3s;
    transition-delay: 0s;
    white-space: nowrap;
    &--icon {
      margin-left: 5px;
    }
  }
  .counter-icon {
    height: 20px;
    width: 20px;
    &--product {
      width: 15px;
      height: 15px;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
}
