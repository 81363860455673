.my-account-badge {
  display: flex;
  align-items: center;
  &--user-icon {
    width: 35px;
    height: 35px;
    margin-right: 15px;
    @media (max-width: 768px) {
      width: 25px;
      height: 25px;
    }
  }
  &__nickname {
    color: #efefef;
    font-weight: 500;
    font-size: 15px;
    transition: 0.9s;
    width: auto;
    .svg {
      transition: 0.9s;
      height: 30px;
      width: 30px;
      path {
        fill: #fff;
      }
    }
  }
  &__chevrone {
    margin-left: 7px;
    width: 15px;
    height: 8px;
    margin-top: 2px;
    transition: 0.3s;
    &--active {
      transform: scale(-1, -1);
      transition: 0.3s;
    }
    path {
      fill: #efefef;
    }
  }
  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    &--active {
      height: 0;
      pointer-events: none;
    }
  }
}
