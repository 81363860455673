.crafts-articles {
  width: 100%;
  right: 0;
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
  }
  &__list {
    display: flex;
    gap: 20px;
    padding: 0 20px 20px 20px;
    width: 100%;
  }
  &__item {
    max-width: calc(100% / 3);
    border-radius: 8px;
    padding: 20px;
    background-color: var(--primary-color-light);
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    &:hover {
      box-shadow: var(--box-shadow--active);
      transition: 0.3s;
    }
  }

  &__item-text .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__item-image {
    height: 300px;
    border-radius: 8px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }
  &__item-heading {
    font-size: 18px;
    letter-spacing: 1px;
    padding: 10px 5px;
    font-weight: 600;
    color: var(--main-color);
  }
  &__item-description {
    padding: 5px;
    color: var(--main-color);
    line-height: 18px;
    font-size: 14px;
  }
}
