.delivery__form {
  .new-category-form {
    .svg--delivery--add {
      width: 25px !important;
      height: 25px !important;
    }
    .regular-field__input input {
      padding-left: 60px;
    }
    .regular-field:nth-child(2) {
      max-width: 170px;
      margin-left: auto;
      .regular-field__input input {
        padding-left: 35px;
      }
    }
  }
}

.new-category-form {
  display: flex;
  background-color: rgba(0, 30, 61, 0.9);
  border-radius: 10px;
  padding-right: 4px;
  align-items: center;
  box-shadow: var(--box-shadow--active);
  &--subcategory {
    background-color: rgba(0, 30, 61, 0.7);
    &:nth-child(2) {
      margin-top: 18px;
    }
    .form__field {
      padding: 17px 15px 17px 46px !important;
    }
  }
  .subcategory__item--single-action {
    margin-right: 15px;
    width: 30px;
    &:nth-child(1) {
      margin-right: 0;
    }
    svg {
      width: 12px;
      path {
        fill: #fff;
      }
    }
  }
  .regular-field__error-label {
    background: unset !important;
    bottom: -15px;
    font-weight: 600;
    top: unset;
    right: 0;
    font-size: 13px;
  }
  .regular-field__input-error {
    border: 0 !important;
  }
  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    .svg {
      margin: 0;
    }
    path {
      fill: #fff;
    }
    transition: 0.3s;
    &:hover {
      scale: 1.1;
      box-shadow: none;
      transition: 0.3s;
    }
  }
  .form__field {
    border: 0px;
    border-radius: 0px;
    color: #fff;
    margin: 0;
    background-color: transparent;
    padding-top: 17px;
    padding-bottom: 17px;

    &:hover,
    &:focus {
      border: 0px !important;
    }
    &:hover,
    &:focus {
      .svg--url path {
        fill: #fff;
      }
    }
  }
  .svg--category,
  .svg--category:hover {
    path {
      fill: #fff;
    }
  }
  .regular-field,
  .regular-field:hover,
  .regular-field:focus {
    .svg path {
      fill: #fff !important;
    }
  }
  .svg--url path {
    fill: #fff;
  }
}

.new-delivery-form {
  padding-right: 0px;
  .svg {
    width: 15px !important;
  }
  .regular-field:nth-child(2) {
    width: 150px;
    margin-left: auto;
    input {
      text-align: right;
    }
  }
}

.add-delivery-form {
  padding-right: 10px;
  .subcategory__item--single-action:first-child {
    margin-right: 10px;
  }
}

.edit-category-form,
.new-category-form--subcategory {
  &--margin {
    margin-right: 0 !important;
  }

  .subcategory__item--single-action {
    margin-right: 15px;
    width: 45px;
  }
}

.edit-category-form {
  .svg--delivery--add,
  .svg--delivery {
    width: 25px !important;
    height: 25px !important;
  }
  .form__field {
    padding-left: 60px;
  }
}
