.Toastify {
  z-index: 999999999999999;
  &__toast {
    min-height: 0 !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    margin-bottom: 0px !important;
    border-radius: 20px !important;
    font-size: 14px;
    font-weight: 500;
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
  &__toast-body {
    display: flex !important;
    padding: 0 !important;
  }
  .notify {
    display: flex;
    &__icon--wrapper {
      height: 44px !important;
      width: 44px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 1;
      .triangle {
        position: absolute;
        content: '';
        width: 0px;
        height: 0px;
        border-top: 60px solid transparent;
        border-bottom: 60px solid transparent;
        border-left: 54px solid var(--client--color);
        z-index: 0;
      }
      &--green {
        background-color: var(--client--color);
      }
      &--red {
        background-color: var(--price-color) !important;
        .triangle {
          border-left: 54px solid var(--price-color);
        }
      }
    }
    &__label {
      display: flex;
      align-items: center;
      margin-left: 20px;
      font-size: 14px;
      font-weight: 600;
      color: var(--main-color);
      opacity: 0.6;
    }
  }
  .svg {
    z-index: 1;
    &--check {
      width: 16px;
      height: 16px;
    }
    path {
      fill: rgb(249, 249, 249);
    }
    &--warning,
    &--product {
      width: 18px;
      height: 18px;
    }
    &--send-mail {
      width: 20px;
      height: 15px;
    }
    &--delivery--add {
      width: 25px;
      height: 25px;
    }
    &--delivery--delete {
      width: 25px;
      height: 25px;
    }
    &--address {
      width: 25px;
      height: 25px;
    }
  }
  &__close-button {
    display: none;
    align-items: center;
    margin-top: 14px;
    margin-right: 10px !important;
  }

  // &__toast-container {
  //   width: 100% !important;
  //   padding: 4px 4px 0 4px !important;
  //   @include md {
  //     width: 365px !important;
  //   }
  // }
  // &__toast-container--bottom-right {
  //   bottom: 82px !important;
  //   @include md {
  //     right: 20px !important;
  //     bottom: 20px !important;
  //   }
  // }
  // &__toast {
  //   min-height: 101px !important;
  //   min-width: 100% !important;
  //   border-radius: unset !important;
  //   border-radius: 8px !important;
  //   padding: 0 !important;
  //   @include md {
  //     right: 4px !important;
  //     min-width: 365px !important;
  //   }
  //   &:last-child {
  //     margin-bottom: 0 !important;
  //   }
  // }
  // &__toast-body {
  //   padding: 14px 20px !important;
  //   margin: unset !important;
  //   width: 100% !important;
  // }
  // &__close-button {
  //   position: absolute !important;
  //   top: 12px !important;
  //   right: 10px !important;
  //   svg {
  //     width: 20px !important;
  //     height: 20px !important;
  //   }
  //   &--default {
  //     color: rgba(107, 107, 107, 0.45) !important;
  //     opacity: 1 !important;
  //   }
  // }
}
