.isLoading {
  position: relative;
  // overflow: hidden;
  .svg {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    bottom: 21px;
  }
  .modal__paragraph--dark {
    position: relative;
  }
}
.contact {
  position: relative;
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
  }
  &--box {
    display: flex;
  }
  &--left {
    display: flex;
    flex-direction: column;
    padding: 50px 40px 40px 70px;
    width: 55%;

    @media (max-width: 756px) {
      width: 55%;
      padding: 80px 30px 40px 30px;
    }
    @media (max-width: 700px) {
      width: 100%;
      padding: 80px 30px 40px 30px;
    }
    .form {
      padding-top: 20px;
      max-width: 400px;
      @media (max-width: 700px) {
        max-width: 100%;
      }
      .btn {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
    .header {
      font-size: 35px;
      line-height: 50px;
      font-weight: 700;
      letter-spacing: 4px;
    }
    .top {
      display: flex;
      flex-direction: column;
      padding-left: 0 !important;
      @media (max-width: 700px) {
        padding-top: 0;
      }
    }
    .paragraph {
      margin-top: 15px;
      line-height: 22px;
      font-weight: 500;
      max-width: 400px;
    }
  }
  &--right {
    display: flex;
    justify-content: flex-end;
    margin-right: -5px;
    position: relative;
    width: 45%;
    @media (max-width: 700px) {
      width: 100%;
      margin-right: 0;
      justify-content: flex-start;
    }
    @media (max-width: 450px) {
      flex-direction: column;
    }
    .sidebar {
      background-color: var(--primary-color);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 100%;
      padding-bottom: 60px;
      width: 200px;
      @media (max-width: 700px) {
        height: auto;
      }
      @media (max-width: 450px) {
        width: 100%;
        padding-top: 60px;
      }
    }
    .socials {
      &--icon {
        height: 30px;
        width: 30px;
        margin-right: 20px;
        cursor: pointer;
        @media (max-width: 450px) {
          height: 40px;
          width: 40px;
          margin-right: 20px;
        }
        path {
          fill: rgba(255, 255, 255, 0.7);
          transition: var(--trans-03);
        }
        &:hover {
          path {
            fill: rgba(255, 255, 255, 0.9);
            transition: var(--trans-03);
          }
        }
      }
    }
  }

  .info {
    position: absolute;
    width: 100%;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--navbar--color);

    @media (max-width: 700px) {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
    }

    @media (max-width: 500px) {
      height: auto;
    }

    &--content {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 50px 20px 20px 40px;

      @media (max-width: 500px) {
        padding: 50px 20px 50px 30px;
      }

      &:after {
        content: '';
        width: 30px;
        height: 30px;
        position: absolute;
        top: -15px;
        left: -15px;
        background-color: var(--editor--color);
      }
    }

    &--head {
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 30px;
      color: var(--primary-color-light);
    }

    &--item {
      display: flex;
      align-items: center;
    }

    &--paragraph {
      color: var(--primary-color-light);
      opacity: 0.7;
      font-weight: 600;
    }

    &--icon {
      width: 30px;
      height: 30px;
      margin-right: 15px;
      path {
        fill: var(--primary-color-light);
        opacity: 0.7;
      }
    }
  }
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
