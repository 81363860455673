.advertisement {
  border: 1px solid var(--border-line);
  border-radius: 8px;
  display: flex;
  margin: 20px 0;
  padding: 20px;
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  background-color: var(--primary-color-light);
  position: relative;
  &:hover {
    box-shadow: var(--box-shadow--active);
    transition: 0.3s;
  }
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    &--wrapper {
      cursor: pointer;
      border-radius: 4px;
      width: 170px;
      height: 140px;
      overflow: hidden;
      flex-shrink: 0;
    }
  }
  .mid {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    padding-top: 6px;
    max-width: 300px;
    width: 100%;
    flex-shrink: 0;
    @media (max-width: 900px) {
      max-width: calc(100% - 194px);
    }
  }
  &--name {
    line-height: 130%;
    font-size: 18px;
    font-weight: 500;
  }
  &--counts {
    margin-top: auto;
  }
  &--count {
    display: flex;
    font-size: 12px;
    line-height: 140%;
    padding: 4px 12px;
    span {
      margin-left: auto;
      font-weight: 600;
    }
    &:first-child {
      border-radius: 8px;
    }
    &:last-child {
      border-radius: 8px;
    }
    &:nth-child(odd) {
      background-color: rgba(128, 128, 128, 0.05);
    }
  }
  &--price {
    font-size: 22px;
    font-weight: 500;
    padding: 10px 20px;
    text-align: right;
    background-color: rgba(128, 128, 128, 0.05);
    border-radius: 8px;
  }
  .right {
    width: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    @media (max-width: 900px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
  &--actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: auto;
    padding: 0 20px;
    background-color: rgba(128, 128, 128, 0.05);
    border-radius: 8px;
    .action {
      font-size: 14px;
      padding: 5px 20px;
      margin: 5px 0;
      border-right: 1px solid var(--border-line);
      cursor: pointer;
      font-weight: 500;
      &--red {
        color: ($color__red);
      }
      &:last-child {
        border-right: 0;
      }
      &:hover {
        font-weight: 600;
      }
    }
  }
}

.advertisement-settings {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  .list {
    right: -20px !important;
  }
  &__content {
    padding: 10px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
  }
  &-icon {
    width: 25px;
    height: 25px;
  }
}
