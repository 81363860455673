.switch-checkbox[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch__wrapper {
  margin-bottom: 16px;
}

.switch-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: 0.1s;
  }
  &:active:after {
    width: 40px;
  }
}

.switch-checkbox:checked + .switch-label {
  background: #161d2e;
}

.switch-checkbox:checked + .switch-label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
