@import "../../../components/settings-dots/settings-dots.scss";

.advertisement-menu {
  display: flex;
  align-items: center;
  &__content {
    display: flex;
    align-items: center;
    padding: 20px;
    // border: 2px solid #c4c4cc;
    border-radius: 24px;
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    width: 100%;
    border: 1px solid var(--border-line);
    border-radius: 8px;
    background-color: var(--primary-color-light);
    .btn-wrapper {
      margin-left: auto;
      margin-bottom: auto;
      flex-shrink: 0;
    }
    &:hover {
      transition: 0.3s;
      box-shadow: var(--box-shadow--active);
    }
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
  }
  &__list {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
  &__item {
    display: flex;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #c4c4cc;
    border-radius: 14px;
    // margin: 0 10px;
    transition: 0.3s;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 20px;
    }
    &--active,
    &:hover {
      transition: 0.3s;
      background-color: #fff;
      box-shadow: var(--box-shadow);
      background-color: #161d2e;
      .advertisement-menu__item--name,
      .advertisement-menu__item--count {
        color: #fff;
        transition: 0.3s;
      }
    }
    &--name {
      transition: 0.3s;
      font-size: 12px;
      font-weight: 600;
      color: #161d2e;
    }
    &--count {
      transition: 0.3s;
      margin-left: 10px;
      font-size: 12px;
      color: #161d2e;
    }
  }
}
