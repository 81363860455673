.monthly-sales {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: var(--primary-color-light);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: var(--box-shadow--active);
  }
  &__CircularProgressbar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 250px;
    &__content {
      max-width: 150px;
    }
    .CircularProgressbar .CircularProgressbar-path {
      stroke: #161d2e;
    }
    .CircularProgressbar-text {
      fill: #161d2e !important;
    }
  }
}
.monthly-sales-right {
  display: flex;
  height: 100%;
  align-items: center;
}
.monthly-sales-text {
  font-size: 14px;
  max-width: 250px;
  line-height: 20px;
  font-weight: 600;
}
