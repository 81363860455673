.my-device-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  .btn-wrapper {
    margin-top: auto;
  }
  &__container {
    width: 100%;
    height: 100%;
    max-width: 400px;
  }
}

