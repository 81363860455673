.crafts-category {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__content {
    padding: 30px 100px;
    background-color: rgba(19, 19, 19, 0.1);
  }
  &__list {
    display: flex;
    justify-content: center;
    .slick-slider {
      width: 100%;
    }
  }
  &__item {
    color: var(--primary-color-light);
    margin: 0 20px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 400;
    transition: 0.1s;
    &:hover {
      transition: 0.1s;
      font-weight: 600;
    }
    &--active {
      font-weight: 600;
    }
  }
}
