@import "./components/events-calendar.scss";
@import "./components/legend.scss";
@import "./components/events-of-the-day.scss";

.events-page {
  &--top {
    display: flex;
    gap: 20px;
  }
}

.events-page-section {
  padding: 20px;
  box-shadow: var(--box-shadow);
  background-color: var(--primary-color-light);
  border-radius: 10px;
  height: 100%;
  display: flex;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    box-shadow: var(--box-shadow--active);
  }
  &__container {
    display: flex;
    flex-direction: column;
    &--calendar {
      max-width: 700px;
      max-height: 700px;
      height: 100%;
      flex-shrink: 0;
    }
  }
  &__header {
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px;
    position: relative;
    color: #161d2e;
    margin-bottom: 20px;
    display: flex;
    // justify-content: space-between;
    min-height: 34px;
    align-items: center;
  }
  &--calendar {
    padding: 20px 40px;
  }
}
