.events {
  &__day {
    padding-top: 30px !important;
    width: 100%;
    .calendar-carts {
      display: flex;
      flex-direction: column;
      &__month {
        font-size: 12px;
        color: rgba(0, 30, 61, 0.7);
        font-weight: 700;
        padding: 5px 19px 0;
      }
      &__date {
        width: 90px;
        height: 60px;
        position: relative;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-shrink: 0;
        .svg {
          width: 60px;
          height: 60px;
          position: absolute;
          path {
            fill: var(--main-color);
          }
        }
      }
      &__date-number {
        margin-top: 18px;
        font-size: 24px;
        font-weight: 20px;
        font-weight: 600;
        color: var(--main-color);
      }
      &__descript {
        display: flex;
        flex-direction: column;
        padding: 6px 20px 6px 0;
        margin: 14px 0;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          height: 1px;
          left: -20px;
          bottom: -16px;
          width: calc(100% + 40px);
          background-color: rgba(0, 30, 61, 0.7);
        }
        &:first-child {
          margin-top: -15px;
        }
      }
      &__descript-title {
        font-size: 14px;
        font-weight: 600;
        color: var(--main-color);
        margin-bottom: 5px;
      }
      &__descript-second-line {
        font-size: 12px;
        color: rgba(0, 30, 61, 0.7);
        font-weight: 700;
      }
    }
  }
  &__container {
    width: 100%;
  }
}
