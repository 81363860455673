.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  // padding-left: 20px;
  margin-top: -20px;
}
.category-page {
  display: flex;
  flex-direction: column;

  .content {
    width: 100%;
    max-width: 1400px;
    display: flex;
    &__wrapper {
      margin: 30px;
      height: calc(100vh - 124px);
      display: flex;
      justify-content: center;
    }
  }
}

.category {
  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    padding: 30px;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    border-radius: 8px;
    margin-bottom: auto;
    width: 100%;
    &:hover {
      box-shadow: var(--box-shadow--active);
    }
  }
  &__item {
    padding: 12px 15px 12px 20px;
    // padding: 3px 0;
    // background-color: rgba(0, 30, 61, 0.9);
    background-color: rgba(0, 30, 61, 0.9);
    border-radius: 4px;
    margin: 26px 0;
    color: #fff;
    font-weight: 500;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    width: 100%;
    position: relative;
    &:first-child {
      margin-top: 0;
    }
    &:hover {
      box-shadow: var(--box-shadow--active);
    }
    &--wrapper {
      display: flex;
    }
    &--cancel {
      transform: rotate(45deg);
      transition: 0.3s;
    }
    .svg {
      margin-right: 10px;
      path {
        fill: #fff;
      }
    }
    .svg--plus {
      margin-right: 0;
      transition: 0.3s;
    }
    &--add {
      background-color: $color__gray;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 12px 15px 12px 20px;
      margin-bottom: 0;
    }
  }
}
.subcategory {
  &__list {
    margin-left: 15px;
  }
  &__item {
    margin: 26px 0;
    padding: 12px 15px 12px 20px;
    background-color: rgba(0, 30, 61, 0.7);
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--box-shadow);
    &:hover {
      box-shadow: var(--box-shadow--active);
    }

    &--actions {
      display: flex;
      .svg--plus {
        width: 12px;
      }
    }

    .left {
      display: flex;
      align-items: center;
      span::first-letter {
        text-transform: uppercase;
      }
    }
    .svg {
      margin-right: 10px;
      path {
        fill: #fff;
      }
    }
    &--single-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.3s;
      &--absolute {
        position: absolute;
        right: -40px;
      }
      &--plus-rotate {
        transform: rotate(45deg);
      }
      &--chevrone-rotate {
        transform: scale(-1);
      }
      &--no-click {
        cursor: auto;
      }
      .svg {
        margin-right: 0 !important;
      }
      .svg--minus path {
        fill: rgba(208, 0, 0, 0.8);
      }
      .svg--chevrone {
        transition: 0.3s;
        max-width: 14px;
        path {
          fill: var(--main-color);
        }
      }
    }
  }
}

.category-modal-remove {
  padding: 5px 10px;
  .paragraph {
    display: flex;
    align-items: center;
    margin: 10px 0;
    color: var(--main-color);
    font-size: 16px;
    font-weight: 600;
  }
  .svg--product {
    width: 20px;
    height: 20px;
  }
  .svg {
    margin-right: 10px;
    path {
      fill: var(--main-color);
    }
  }
}
