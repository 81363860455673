.add-product-page {
  display: flex;
  flex-direction: column;
  &__wrapper {
    display: flex;
  }
}
.addProductForm {
  width: 100%;
  max-width: 800px;
  &__back {
    &--icon {
      transform: rotate(90deg);
      .svg path {
        fill: rgba(0, 30, 61, 0.4);
      }
    }
    &--wrapper {
      cursor: pointer;
      display: flex;
      margin-top: 15px;
      margin-left: 30px;
    }
    &--label {
      font-weight: 500;
      font-size: 14px;
      color: rgba(0, 30, 61, 0.4);
    }
  }
  &--description {
    .customize-field {
      width: 100% !important;
      max-width: 100% !important;
    }
    textarea {
      width: 100%;
    }
  }
  &__wrapper {
    // padding: 0 20px;
    @media (max-width: 756px) {
      padding: 0;
    }
  }
  .regular-field__wrapper {
    display: flex;
    .custom-field {
      width: 50%;
      &:nth-child(2) {
        margin-left: 60px;
      }
    }
  }
  &--basic {
    position: relative;
    .svg--customize-form {
      position: absolute;
      bottom: 0;
      max-width: 300px;
      max-height: 300px;
      right: 0;
    }
  }
  &__box {
    border: 1px solid var(--border-line);
    border-radius: 8px;
    display: flex;
    // margin: 0 0 30px 30px;
    padding: 30px 40px;
    background-color: var(--primary-color-light);
    box-shadow: var(--box-shadow);
    transition: 0.3s;
    &:hover {
      box-shadow: var(--box-shadow--active);
      transition: 0.3s;
    }
    &--list {
      display: flex;
      flex-direction: column;
      .customize-field {
        &:nth-child(1) {
          max-width: 450px;
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          max-width: 300px;
        }
      }
      .select-field__wrapper {
        max-width: 350px;
        &:nth-child(3) {
          max-width: 300px;
        }
      }
      .react-select {
        &__option {
          font-weight: 700;
          padding: 15px;
          color: rgba(0, 0, 0, 0.6) !important;
          // background-color: green !important;
        }
      }
    }
  }
  &__select--disabled {
    .select-field {
      pointer-events: none !important;
      opacity: 0.4;
      &__label {
        opacity: 0.4;
      }
    }
  }
}

// <div class="react-select__menu">
//   <div class="react-select__menu-list">
//     <div class="react-select__option">...</div>
//   </div>
// </div>

.add-product-photo {
  &__wrapper {
    border-top: 1px solid var(--border-line);
    border-bottom: 1px solid var(--border-line);
    margin: 20px 0;
    padding: 20px 0 40px;
    position: relative;
    overflow: hidden;
  }
  &--error {
    text-align: right;
    font-size: 13px;
    font-weight: 500;
    color: var(--price-color);
    margin-top: 2px;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    &--content {
      margin-left: 15px;
    }
    .heading {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .paragraph {
      font-size: 14px;
      margin-top: auto;
    }
    .svg {
      height: 100px;
      width: 100px;
    }
  }
}

.delivery-product {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 30px;
  position: relative;
  transition: var(--tran-03);
  &--heading {
    margin-top: 14px;
  }
  &--error {
    position: absolute;
    right: 0;
    bottom: -17px;
    position: absolute;
    font-size: 13px;
    color: var(--price-color);
    font-weight: 500;
  }
  &--errors {
    border: 1px dashed var(--price-color);
  }
  &__show-more {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 30px;
    margin-top: -10px;
    text-align: right;
    font-weight: 500;
    color: var(--main-color);
  }
  input {
    text-align: right;
    border: 0;
    max-width: 35px;
    padding: 5px;
    margin-right: 3px;
    border-radius: 4px;
  }
  &__item {
    display: flex;
    padding: 10px 0;
    background-color: #f7f8fd;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    position: relative;
    padding-right: 30px;
    transition: var(--tran-03);
    &:hover {
      background-color: #eeeff6;
      transition: var(--tran-03);
    }
    &:nth-child(n + 1) {
      border-bottom: none;
    }
    &:last-child {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    }
    .check {
      cursor: pointer;
      width: 50px;
      &:after {
        content: "";
        position: absolute;
        left: 17px;
        width: 10px;
        height: 10px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        top: 19px;
      }
    }
    &--icon {
      height: 20px;
      width: 20px;
      path {
        fill: rgba(0, 0, 0, 0.5);
      }
      &--wrapper {
        margin: 0 15px 0 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &--label,
    &--price {
      font-size: 14px;
      display: flex;
      align-items: center;
      opacity: 0.6;
      transition: var(--tran-03);
    }
    &--price {
      margin-left: auto;
    }
    &--active {
      background-color: #eeeff6;
      transition: var(--tran-03);
      .delivery-product__item--label {
        font-weight: 600;
        transition: var(--tran-03);
      }
      .check {
        width: 50px;
        &:before {
          content: "";
          left: 21px;
          top: 20px;
          width: 2px;
          height: 6px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          z-index: 9;
        }
        &:after {
          background-color: var(--primary-color);
        }
      }
    }
    &--hidden {
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
    }
  }
}
