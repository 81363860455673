.forum-sidebar-right {
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &--question {
    max-width: 600px;
    width: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__header {
    font-size: 20px;
    font-weight: 600;
    position: relative;
    color: #161d2e;
    margin-bottom: 20px;
  }
  &__search {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    padding: 0 10px;
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      color: #161d2e;
      background-color: transparent;
    }
  }
  &__tags {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &__content {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
  &__last-added {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__header {
      font-size: 20px;
      font-weight: 600;
      position: relative;
      color: #161d2e;
      margin-bottom: 20px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
