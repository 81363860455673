@import "./components/top.scss";
@import "./components/MonthlySales.scss";

.my-advertisements-page,
.delivery-page,
.category-page,
.add-product-page,
.users {
  display: flex;
  flex-direction: column;
  .top {
    align-items: center;
    background-color: #fff;
    &__header {
      width: 100%;
      max-width: 1400px;
      display: flex;
      align-items: center;
      padding: 30px 50px 30px 30px;
    }
  }
}

.advertisements-page {
  display: flex;
  gap: 20px;
}

.advertisements-left-side {
  flex-shrink: 0;
}
.advertisements-right-side {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
