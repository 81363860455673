.readyToUpload {
  &__item {
    .readyToUpload__image-buttons {
      margin-top: 50px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  &__item {
    padding: 16px;
    border: 1px solid #cecece;
    border-radius: 14px;
    max-width: calc(100% / 4 - 15px);
    width: 100%;
    padding-bottom: 70px;
    position: relative;
    &:hover {
      .hover {
        visibility: visible;
      }
    }
  }
  &__image {
    margin-bottom: 20px;
    border-radius: 14px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 14px;
    }
  }
  &__caption {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    span {
      font-weight: 500;
      font-size: 14px;
      margin-right: 10px;
      line-height: 20px;
    }
  }
  &__caption-heading {
    margin-bottom: 10px;
  }
  &__image-buttons {
    visibility: hidden;
    justify-content: space-between;
    display: flex;
    position: absolute;
    bottom: 15px;
    width: calc(100% - 30px);
    .btn-wrapper {
      width: calc(50% - 7px);
      .btn {
        width: 100%;
      }
    }
  }
  &__actions {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    .btn-wrapper {
      margin-right: auto;
    }
  }
  &__actions-item {
    padding: 4px 8px;
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 500;
    width: 190px;
    cursor: pointer;
    &:hover {
      font-weight: 600;
    }
  }
  &__checkbox {
    position: absolute;
    top: 7px;
    right: 7px;
    input {
      width: 20px;
      height: 20px;
    }
  }
}

.hours-schedule {
  &__form {
    margin: 50px 0 0 0;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .form-group {
      width: calc(50% - 10px);
    }
  }
  &__form-heading--text {
    font-weight: 500;
  }
  &__form-fields {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}
