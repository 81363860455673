.events-calendar {
  height: 100%;

  .react-calendar {
    &__navigation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 6px 0 12px;
      &__prev2-button,
      &__next2-button {
        display: none;
      }
      &__label {
        border: 0;
        background-color: unset;
        font-size: 20px;
        padding: 10px 0 20px;
        &:first-letter {
          text-transform: capitalize;
        }
        &__labelText {
          font-weight: 600;
          color: rgba(0, 30, 61, 0.7);
        }
      }
      &__arrow {
        border: 0;
        background-color: unset;
        font-size: 40px;
        width: 40px;
        padding-bottom: 15px;
        cursor: pointer;
        color: rgba(0, 30, 61, 0.7);
      }
    }
    &__month-view {
      &__weekdays {
        margin-bottom: 10px;
        &__weekday {
          text-transform: uppercase;
          text-align: center;
          font-size: 16px;
          text-decoration: none;
          abbr {
            text-decoration: none;
            font-weight: 600;
            color: rgba(0, 30, 61, 0.7);
          }
        }
      }
      &__days__day--neighboringMonth {
        abbr {
          cursor: pointer;
          color: rgba(0, 30, 61, 0.2);
        }
      }
    }
    &__tile {
      background-color: unset;
      border: 0;
      padding: 5px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      &--now {
        abbr {
          background-color: #54df8e;
          color: var(--primary-color-light);
        }
      }

      abbr {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// react-calendar__tile--now
// react-calendar__tile--active
// react-calendar__tile--range
// react-calendar__tile--rangeStart
// react-calendar__tile--rangeEnd
// react-calendar__tile--rangeBothEnds
// react-calendar__month-view__days__day
