.settings-dots {
  padding-right: 15px;

  &__wrapper {
    cursor: pointer;
    position: relative;
  }

  .icon {
    height: 25px;
    width: 25px;
    svg {
      width: 100%;
      height: 100%;
    }
    &--disabled {
      pointer-events: none;
    }
    path {
      fill: var(--text-color);
    }
  }
  .list {
    margin-top: 10px;
    position: absolute;
    right: 0;
    border-radius: 10px;
    background-color: var(--primary-color-light);
    box-shadow: var(--box-shadow--white);

    &--item {
      width: 100%;
      white-space: nowrap;
      padding: 14px 20px;
      font-size: 14px;
      text-transform: capitalize;
      font-weight: 600;
      transition: var(--tran-03);
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        transition: var(--tran-03);
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .view {
    max-height: 0;
    overflow: hidden;
    transition: var(--tran-04);
    &--active {
      // overflow: auto;
      max-height: 200px;
      background-color: var(--primary-color-light);
      box-shadow: var(--box-shadow--active);
      transition: var(--tran-04);
      z-index: 2;
    }
  }
}
