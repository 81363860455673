.btn {
  border: unset;
  padding: 8px 16px;
  box-shadow: 0px 4px 5px rgba(1, 36, 68, 0.2);
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  &--icon {
    display: flex;
    align-items: center;
    &--left {
      margin-right: 10px;
    }
    &--right {
      margin-left: 10px;
    }
    svg {
      width: 14px;
    }
  }
  &--endTile {
    margin-bottom: -20px;
  }
  &__label {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
  }
  &--normal {
    background-color: var(--first-color);
    color: #ffffff;
    padding: 10px 30px;
    transition: 0.3s;
    &:hover {
      background-color: #000;
      transition: 0.3s;
    }
  }
  &--normal-stroke {
    background-color: transparent;
    color: var(--second-color);
    padding: 10px 30px;
    transition: 0.3s;
    border: 1px solid var(--second-color);
    &:hover {
      box-shadow: rgba(255, 255, 255, 0.3) 0px 1px 2px 0px,
        rgba(255, 255, 255, 0.15) 0px 2px 6px 2px;
    }
  }

  &--default {
    background-color: #023e8a;
    color: #fff;
    transition: background-color 0.3s ease-out;
    &:hover {
      background-color: #001930;
      transition: background-color 0.3s ease-out;
      box-shadow: 0px 4px 6px rgba(1, 36, 68, 0.14);
    }
  }
  &--whiteBlue {
    background-color: #e4e9f7;
    color: #001930;
    transition: 0.3s;
    border: 0.5px solid #001930;
    svg path {
      fill: #003566;
    }
    &:hover {
      background-color: #001930;
      transition: background-color 0.3s ease-out;
      box-shadow: 0px 4px 6px rgba(1, 36, 68, 0.14);
      color: #fff;
    }
  }
  &--default--white {
    background-color: #003566;
    color: #fff;
    svg path {
      fill: #fff;
    }
    &:hover {
      background-color: #001930;
      transition: background-color 0.3s ease-out;
      box-shadow: 0px 4px 6px rgba(1, 36, 68, 0.14);
    }
  }
  &--frame-white {
    color: #fff;
    border: 1px solid #003566;
    &:hover {
      background-color: #003566;
      transition: 0.3s ease-out;
      box-shadow: 0px 4px 6px rgba(1, 36, 68, 0.4);
    }
  }
  &--frame-dark {
    color: #161d2e;
    border: 1px solid #161d2e;
    &:hover,
    &--active {
      svg path {
        transition: 0.3s ease-out;
        fill: #fff;
      }
    }
    svg path {
      fill: #003566;
    }
    &:hover,
    &--active {
      box-shadow: 0px 4px 6px rgba(1, 36, 68, 0.14);
      background-color: #161d2e;
      transition: 0.3s ease-out;
      color: #fff;
      .svg--basket {
        path {
          fill: #003566;
          stroke: #fff;
        }
      }
    }
    .svg--basket {
      path {
        fill: transparent;
        stroke: #003566;
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  &--left {
    padding: 20px 0;
  }
  &--right {
    padding: 20px 0;
    justify-content: flex-end;
  }
  &--center {
    padding: 20px 0;
    justify-content: center;
  }
  &--fullWidth {
    padding: 20px;
  }
  &--center-full {
    width: 100%;
    padding: 20px 0;
    justify-content: center;
    .btn {
      width: 100%;
    }
  }
}
