.navBar {
  &__wrapper {
    width: 100%;
    z-index: 99999;
    background-color: var(--navbar--color);
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
  }
  &--show {
    transition: transform 200ms ease-in-out;
    position: fixed;
    transform: translateY(0%);
  }
  &--hidden {
    position: fixed;
    transition: transform 200ms ease-in-out;
    transform: translateY(-100%);
  }
}

// .headroom {
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 1;
// }
// .headroom--unfixed {
//   position: relative;
//   transform: translateY(0);
// }
// .headroom--scrolled {
//   transition: transform 200ms ease-in-out;
// }
// .headroom--unpinned {
//   position: fixed;
//   transform: translateY(-100%);
// }
// .headroom--pinned {
//   position: fixed;
//   transform: translateY(0%);
// }
// .headroom-wrapper {
//   width: 100%;
// }
